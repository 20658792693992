import React from 'react'

export default function HiddenFormFields({ user, pictures, authenticity_token }) {
  // debugger
  return (
    <>
      <input
        type="hidden"
        name="authenticity_token"
        value={authenticity_token}
      />
      <input
        type="hidden"
        name="user[first_name]"
        value={user.firstName}
      />
      <input
        type="hidden"
        name="user[last_name]"
        value={user.lastName}
      />
      <input
        type="hidden"
        name="user[nickname]"
        value={user.nickname}
      />
      <input
        type="hidden"
        name="user[email]"
        value={user.email}
      />
      <input
        type="hidden"
        name="user[password]"
        value={user.password}
      />
      <input
        type="hidden"
        name="user[password_confirmation]"
        value={user.passwordConfirmation}
      />
      <input
        type="hidden"
        name="user[country_code]"
        value={user.countryCode}
      />
      <input
        type="hidden"
        name="user[phone]"
        value={user.phone}
      />
      <input
        type="hidden"
        name="user[country_id]"
        value={user.country}
      />
      <input
        type="hidden"
        name="user[club_id]"
        value={user.isAffiliated ? user.club : ''}
      />
      <input
        type="hidden"
        name="user[club_president]"
        value={user.clubPresident}
      />
      <input
        type="hidden"
        name="user[bio]"
        value={user.bio}
      />
      <input
        type="hidden"
        name="user[year_joined]"
        value={user.yearJoined}
      />
      <input
        type="hidden"
        name="user[profile_image_id]"
        value={user.profileImageId}
      />
      <input
        type="hidden"
        name="user[address_attributes][street1]"
        value={user.address.street1}
      />
      <input
        type="hidden"
        name="user[address_attributes][street2]"
        value={user.address.street2}
      />
      <input
        type="hidden"
        name="user[address_attributes][city]"
        value={user.address.city}
      />
      <input
        type="hidden"
        name="user[address_attributes][state_province]"
        value={user.address.stateProvince}
      />
      <input
        type="hidden"
        name="user[address_attributes][postal_code]"
        value={user.address.postalCode}
      />
      <input
        type="hidden"
        name="user[address_attributes][country_other]"
        value={user.address.countryOther}
      />
      {/* <input type="file" name="user[profile_image]" id="user_profile_image"
        defaultValue={pictures} value={pictures[0][0]}
      /> */}
      {/* <input
        type="hidden"
        name="user[][]"
        value={user.address.countryOther}
      /> */}
    </>
  )
}
