document.addEventListener('turbolinks:load', function() {
  // use the same modal for all translation fields
  initializeTranslationModal()
  let modal = document.getElementById("generic-modal")
  if (modal!=null) {
    modal.style.display = "none"
  }
});

function initializeTranslationModal() {
  let modal = document.getElementById("generic-modal")
  // Get the <span> element that closes the modal. When the user clicks on <span> (x), close the modal
  let close = modal?.querySelector(".ModalIconClose")
  if (close!=null) {
    close.onclick = function() {
      modal.style.display = "none"
    }  
  }
  let closeButton = modal?.querySelector(".Button")
  if (closeButton!=null) {
    closeButton.onclick = function() {
      modal.style.display = "none"
    }  
  }

  // Get all links that open the modal. When the user clicks on one, open the modal and call ajax 
  let translateLinkAboutTheClub = document.querySelector("#translateAboutTheClub")
  if (translateLinkAboutTheClub!=null) {
    translateLinkAboutTheClub.addEventListener('click', function() {
      resetTranslationModal(translateLinkAboutTheClub)
    })
  }
  let translateLinkAboutTheJourney = document.querySelector("#translateAboutTheJourney")
  if (translateLinkAboutTheJourney!=null) {
    translateLinkAboutTheJourney.addEventListener('click', function() {
      resetTranslationModal(translateLinkAboutTheJourney)
    })
  }
  let translateLinkAboutTheRegion = document.querySelector("#translateAboutTheRegion")
  if (translateLinkAboutTheRegion!=null) {
    translateLinkAboutTheRegion.addEventListener('click', function() {
      resetTranslationModal(translateLinkAboutTheRegion)
    })
  }  
  
  // When the user clicks anywhere outside of the modal, close it
  window.onclick = function(event) {
    if (event.target == modal) {
      modal.style.display = "none"
    }
  }
}


function resetTranslationModal(item) {
  let modal = document.getElementById("generic-modal")
  let targetLanguage = item.getAttribute("data-language")
  let textToTranslate = null;
  if (modal!=null) {
    let label = modal.querySelector(".translationLabel")
    if (label!=null) {
      const itemContainer = item.parentElement
      const originalLabel = itemContainer?.querySelector("#label")
      label.innerHTML = originalLabel?.innerHTML
      const originalText = itemContainer?.querySelector("#text-to-translate")
      textToTranslate = originalText?.innerHTML
    }
    let content = modal.querySelector(".modalContent")
    if (content!=null) {
      content.style.display="none"
    }
    let error = modal.querySelector(".translationError")
    let spinner = modal.querySelector(".spinner")
    if (textToTranslate!=null && targetLanguage!=null) {

      callGoogleTranslateApi(targetLanguage, textToTranslate, content, error, spinner)

      if (spinner!=null) {
        spinner.style.display="block"
      }
      if (error!=null) {
        error.style.display="none"
      }
    }
    else {
      if (spinner!=null) {
        spinner.style.display="none"
      }
      if (error!=null) {
        error.innerHTML = "ERROR: There was an error in translating the text."
        error.style.display="block"
      }
    }
    modal.style.display = "block"
  }
}

function callGoogleTranslateApi(targetLanguage, textToTranslate, contentElement, errorElement, spinner) {
  var xhttp;
  xhttp = new XMLHttpRequest();
  xhttp.onreadystatechange = function() {
    let success = false
    let output = null

    if (this.readyState == 4 && this.status == 200) {
      const jsonObj = JSON.parse(this.responseText)
      success = jsonObj?.success
      output = jsonObj?.output
    }


    if (success) {
      contentElement.innerHTML = output
      errorElement.innerHTML = ""
      contentElement.style.display = "block"
      errorElement.style.display = "none"  
    }
    else {
      contentElement.innerHTML = "";
      errorElement.innerHTML = "ERROR: There was an error in translating the text.";
      contentElement.style.display = "none"
      errorElement.style.display = "block"
    }
    spinner.style.display = "none"
  }
  xhttp.open("GET", "/translate?target=" + targetLanguage + "&text=" + encodeURIComponent(textToTranslate), true);
  xhttp.send();   
}

