import React from 'react'
import Step from './Step'

export default function StepsBar({ setStep, step, submitPageOne, submitPageTwo, submitPageThree }) {
  const circleColor = { borderColor: "#9C4ABE", backgroundColor: "#9C4ABE" }
  const numberColor = { color: "#fff" }
  const numberDisableColor = { color: "#D9D9D9" }

  const fillStepOne = step > 0 ? numberColor : numberDisableColor;
  const fillCircleOne = step > 0 ? circleColor : null;
  const fillStepTwo = step > 1 ? numberColor : numberDisableColor;
  const fillCircleTwo = step > 1 ? circleColor : null;
  const fillStepThree = step > 2 ? numberColor : numberDisableColor;
  const fillCircleThree = step > 2 ? circleColor : null;
  const fillStepFour = step > 3 ? numberColor : numberDisableColor;
  const fillCircleFour = step > 3 ? circleColor : null;

  return (
    <div className="flex justify-center">
      <div
        style={fillCircleOne}
        className="NumberCircle mr2"
        onClick={() => setStep(1)}
      >
        <p style={fillStepOne}>1</p>
      </div>
      <div
        style={fillCircleTwo}
        className="NumberCircle mr2"
        onClick={() => submitPageOne()}
      >
        <p style={fillStepTwo}>2</p>
      </div>
      <div
        style={fillCircleThree}
        className="NumberCircle mr2"
        onClick={() => submitPageTwo()}
      >
        <p style={fillStepThree}>3</p>
      </div>
      <div
        style={fillCircleFour}
        className="NumberCircle mb3"
        onClick={() => submitPageThree()}
      >
        <p style={fillStepFour}>4</p>
      </div>
    </div>
  )
}
