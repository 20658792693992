import { ajax } from 'jquery';
import Sortable from 'sortablejs';
document.addEventListener('turbolinks:load', function() {
    adminClbimagesSortListners();

    function adminClbimagesSortListners(){
        var imagesSortDivcstm = $('#cstm_sort')
    
        if (imagesSortDivcstm.length === 1) {
          var current_form = $('#cstm_sort').attr('class');
          var ajax_url;
          var placevar;
          if (current_form == 'journey_photos'){
            ajax_url = '/dragdropimagesortingjourneyphotos/';
            placevar = 'Journey'
          }else{
            ajax_url = '/dragdropimagesorting/';
            placevar = 'Club'
          }

          var cstm_sort = document.getElementById('cstm_sort');
          var sortable = Sortable.create(cstm_sort, { 
            animation: 150,
            ghostClass: 'light-bg',
            onUpdate: function () {
              var order = [];
              $('#cstm_sort .sort_row_single_cstm').each(function() {
                order.push([$(this).attr("data-item-id"), ($(this).index() + parseInt(1)) ]);
              });
              $.ajax({
                url: ajax_url,
                method: "POST",  
                dataType: "json",
                data: {"order": JSON.stringify(order)},
                error: function (xhr, status, error) {
                    console.error('AJAX Error: ' + status + error);
                    var respnsecontent = '<div class="Flash bg-white p2 shadow r-sm z10"><div class="text-right"><div class="Flash-Close Icon Icon-sm fill-gray100 pointer lh0" onclick="$(this).parent().parent().hide(); return false;"><svg xmlns="http://www.w3.org/2000/svg" viewBox="-2 -2 24 24" preserveAspectRatio="xMinYMin" class="jam jam-close-circle"><path d="M11.414 10l2.829 2.828a1 1 0 0 1-1.415 1.415L10 11.414l-2.828 2.829a1 1 0 1 1-1.415-1.415L8.586 10 5.757 7.172a1 1 0 0 1 1.415-1.415L10 8.586l2.828-2.829a1 1 0 0 1 1.415 1.415L11.414 10zM10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"></path></svg></div></div>'+'There was an error saving the'+ placevar +'photos Order'+'</div>'
                    $("#flash-section").html(respnsecontent);
                },
                success: function (response) {
                    var respnsecontent = '<div class="Flash bg-white p2 shadow r-sm z10"><div class="text-right"><div class="Flash-Close Icon Icon-sm fill-gray100 pointer lh0" onclick="$(this).parent().parent().hide(); return false;"><svg xmlns="http://www.w3.org/2000/svg" viewBox="-2 -2 24 24" preserveAspectRatio="xMinYMin" class="jam jam-close-circle"><path d="M11.414 10l2.829 2.828a1 1 0 0 1-1.415 1.415L10 11.414l-2.828 2.829a1 1 0 1 1-1.415-1.415L8.586 10 5.757 7.172a1 1 0 0 1 1.415-1.415L10 8.586l2.828-2.829a1 1 0 0 1 1.415 1.415L11.414 10zM10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"></path></svg></div></div>'+ placevar +'Photos Order saved successfully'+'</div>'
                    $("#flash-section").html(respnsecontent);
                    var ivar = 1;
                    $('#cstm_sort .sort_row_single_cstm .club-photo-index-div').each(function() {
                      $(this).text(ivar);
                      ivar++;
                    });
                    
                }
              });
            },
          });
        }
      }

});