import React, { useState, useMemo, useEffect, useRef } from "react"
import SignupOne from './SignupOne'
import SignupTwo from './SignupTwo'
import SignupThree from './SignupThree'
import SignupFinal from './SignupFinal'
import StepsBar from "./StepsBar"
import HiddenFormFields from './HiddenFormFields'
//import DocumentImage from '../images/jamicons/document-f.svg'
import SimpleReactValidator from 'simple-react-validator';
import { clearPersistentStorage, initIdleTimer, usePersistentState } from './PersistentStorage';

function PostForm({ authenticity_token, countries, clubs, languages }) {

  initIdleTimer();

  const [, forceUpdate] = usePersistentState( 'ffi-state-forceUpdate')
  const [, forceUpdateTwo] = usePersistentState( 'ffi-state-forceUpdateTwo')
  const [, forceUpdateThree] = usePersistentState( 'ffi-state-forceUpdateThree')
  const [, forceUpdateFour] = usePersistentState( 'ffi-state-forceUpdateFour')
  const simpleValidator = useRef(new SimpleReactValidator())
  const simpleValidatorTwo = useRef(new SimpleReactValidator())
  const simpleValidatorThree = useRef(new SimpleReactValidator())
  const simpleValidatorFour = useRef(new SimpleReactValidator())

  const [emailExists, setEmailExists] = usePersistentState( 'ffi-state-emailExists', false);

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  function submitPageTwo(e) {
    const formValid = simpleValidatorTwo.current.allValid()
    const formValidTwo = simpleValidatorTwo.current.allValid()
    if (step == 1) {
      if (!formValidTwo || !formValid) {
        simpleValidatorTwo.current.showMessages()
        simpleValidator.current.showMessages()
        forceUpdateTwo(1)
      }
    } else {
      if (!formValid) {
        simpleValidatorTwo.current.showMessages()
        forceUpdateTwo(1)
      } else {
        setStep(3)

      }
    }
  }
  function submitPageThree(e) {
    const formValid = simpleValidator.current.allValid()
    const formValidTwo = simpleValidatorTwo.current.allValid()
    const formValidThree = simpleValidatorThree.current.allValid()
    if (!formValidThree || !formValidTwo || !formValid) {
      simpleValidatorThree.current.showMessages()
      simpleValidator.current.showMessages()
      forceUpdateThree(1)
      // simpleValidatorTwo.current.showMessages()
    } else {
      setStep(4)
    }
  }
  function submitPageFour(e) {
    const formValid = simpleValidatorFour.current.allValid()
    if (!formValid) {
      simpleValidatorFour.current.showMessages()
      forceUpdateFour(1)
      e.preventDefault();
    } else {
      clearPersistentStorage();
      return;
    }
  }
  function submitPageOne(e) {
    if (step == 1) {
      const token = document.querySelector('[name=csrf-token]').content;
      const email = user.email.toLowerCase();
      fetch(`/validate_email`, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
      })
        .then((data) => data.text())
        .then((res) => {
          const emailResponse = JSON.parse(res)
          const emailExists = emailResponse.emailTaken
          const formValid = simpleValidator.current.allValid()
          if (emailExists) {
            setEmailExists(true);
            return
          } else {
            setEmailExists(false);
          }

          const invalidPasswordMatch = user.password !== user.passwordConfirmation

          if (!formValid) {
            simpleValidator.current.showMessages()
            forceUpdate(1)
          } else {
            if (invalidPasswordMatch) {
              setUser({ ...user })
              forceUpdate(1)
              return
            }
            setStep(step + 1)
          }
        })

      return
    }

    const formValid = simpleValidator.current.allValid()
    if (!formValid) {
      simpleValidator.current.showMessages()
      forceUpdate(1)
    } else {
      setStep(2)
    }
  }

  const [step, setStep] = usePersistentState( 'ffi-state-step', 1)
  const [checked, setCheckbox] = usePersistentState( 'ffi-state-checkbox', {
    facebook: false,
    instagram: false,
    twitter: false,
    whatsapp: false,
    terms: false,
    conduct: false,
  })

  const [pictures, setPictures] = usePersistentState( 'ffi-state-pictures', []);
  const onDrop = picture => {
    const token = document.querySelector('[name=csrf-token]').content
    const formData = new FormData();
    formData.append("profile_image", picture[0]);
    fetch(`/picture_upload`, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': token,
      },
      body: formData
    })
      .then((data) => data.text())
      .then((res) => {
        const profileImageId = JSON.parse(res).id;
        // use the FileReader to decode the picture file.
        let reader = new FileReader();
        let url = reader.readAsDataURL(picture[0]); // for the first(only) picture in this case
        reader.onloadend = function (e) {
          // the reader.result variable will have the url, set the pictureUrl state to that
          setUser({ ...user, profileImageId: profileImageId, pictureUrl: reader.result });
        };
      });
  };

  const [user, setUser] = usePersistentState( 'ffi-state-user', {
    email: "",
    password: "",
    passwordConfirmation: "",
    passwordsMatch: false,
    isAffiliated: true,
    clubPresident: false,
    firstName: "",
    lastName: "",
    nickname: "",
    countryCode: "",
    phone: "",
    country: "",
    club: "",
    bio: "",
    yearJoined: "",
    facebook: "",
    instagram: "",
    twitter: "",
    whatsapp: "",
    language: "",
    languageName: "",
    emailPreference: {
      alsoEnglish: false,
      opt_in: false
    },
    profileImageId: "",
    address: {
      street1: "",
      street2: "",
      city: "",
      stateProvince: "",
      postalCode: "",
      countryOther: "",
      // countryId: user.country
    }
  })

  useEffect(() => {
    scrollToTop();
  }, [step])

  const clubsFiltered = clubs
    .filter((club) => {
      return club.country_id === Number(user.country);
    })
    .sort((a, b) => {
      return a.displayName > b.displayName ? 1 : a.displayName < b.displayName ? -1 : 0;
    });

  let component = null
  let deviseLinks = null
  switch (step) {
    case (1):
      component = (
        <SignupOne
          user={user}
          setUser={setUser}
          validator={simpleValidator}
          submitForm={submitPageOne}
          step={step}
          emailExists={emailExists}
        />
      )
      break;
    case 2:
      component = (
        <SignupTwo
          countries={countries}
          clubs={clubsFiltered}
          setUser={setUser}
          user={user}
          validator={simpleValidatorTwo}
          submitForm={submitPageTwo}
          setStep={setStep}
        />
      )
      break;
    case 3:

      component = (
        <SignupThree
          onDrop={onDrop}
          setPictures={setPictures}
          pictures={pictures}
          setUser={setUser}
          setStep={setStep}
          user={user}
          countries={countries}
          validator={simpleValidatorThree}
          submitPageThree={submitPageThree}
        />
      )
      break;
    case 4:

      // component = <SignupFinal />
      break;

    default:
      break;
  }

  return (
    <div>
      <StepsBar
        setStep={setStep}
        step={step}
        submitPageOne={submitPageOne}
        submitPageTwo={submitPageTwo}
        submitPageThree={submitPageThree}
      />
      <div className="roman-alphabet">{I18n.t("sign_up.use_roman_alphabet")}</div>
      {component}
      {
        step === 4 ? (
          <>
            <HiddenFormFields
              user={user}
              pictures={pictures}
              authenticity_token={authenticity_token}
            />
            <SignupFinal
              setUser={setUser}
              user={user}
              checked={checked}
              setCheckbox={setCheckbox}
              setStep={setStep}
              languages={languages}
              validator={simpleValidatorFour}
              submitForm={submitPageFour}
            />
          </>
        ) : null
      }
    </div>
  );
}

export default PostForm;
