import React from 'react'
import CheckShield from '../images/jamicons/shield-check-f.svg'

export default function Requirement({ message, isValid }) {
  return (
    <>
      <li style={{ listStyle: 'circle' }}>
        {
          isValid ?
            <img
              src={CheckShield}
              alt="React Logo"
              className="fill-green"
              style={{ verticalAlign: 'bottom', marginRight: '3px' }}
            /> :
            null
        }
        {message}
      </li>
    </>
  )
}
