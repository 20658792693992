import { clearPersistentStorage } from '../components/PersistentStorage';

document.addEventListener('turbolinks:load', function() {
  var banners = document.getElementsByClassName('home-banner');
  var currentBanner = 0;
  var next = document.getElementById('next-banner');
  var previous = document.getElementById('previous-banner');
  var timeout;
  var bannerInterval;
  var link;
  var target;

  if (banners[0]) {

    bannerInterval = setInterval(nextBanner, 9000);
    // upon login, clear the sessionStorage variables from Sign Up
    clearPersistentStorage();  

    function nextBanner() {
      goToBanner(currentBanner + 1);
    }

    function previousBanner() {
      goToBanner(currentBanner - 1);
    }

    function goToBanner(n) {
      banners[currentBanner].className = 'home-banner';
      currentBanner = (n + banners.length) % banners.length;
      banners[currentBanner].className = 'home-banner showing';
      link = document.getElementById('home-banner-link-' + currentBanner).value;
      if (link == "") {
        link = "javascript:void(0)"
        target = ""
      } else {
        target = "_blank"
      };
      document.getElementById('home-banner-active-link').setAttribute('href', link);
      document.getElementById('home-banner-active-link').setAttribute('target', target);
    }

    next.onclick = function() {
      pauseBannershow();
      nextBanner();
    }

    previous.onclick = function() {
      pauseBannershow();
      previousBanner();
    }

    function pauseBannershow() {
      clearTimeout(timeout);
      clearInterval(bannerInterval);
      timeout = setTimeout(function(){
        playBannershow();
      },
        5000
      );
    }

    function playBannershow() {
      bannerInterval = setInterval(nextBanner, 9000);
    }

    document.addEventListener('turbolinks:before-cache', function() {
      clearTimeout(timeout);
      clearInterval(bannerInterval);
    });
  }

});
