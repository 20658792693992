import React from 'react'
import ImageUploader from "react-images-upload";
import ReactTooltip from 'react-tooltip';
import { years } from './years';
import Info from '../images/jamicons/info'

export default function SignupThree({
  user,
  onDrop,
  setUser,
  setStep,
  validator,
  submitPageThree,
}) {

  const customStreetMessages = { messages: { required: I18n.t("sign_up.validation.required_street") } }
  const customCityMessages = { messages: { required: I18n.t("sign_up.validation.required_city") } }
  const customStateMessages = { messages: { required: I18n.t("sign_up.validation.required_state_province") } }
  const customZipMessages = { messages: { required: I18n.t("sign_up.validation.required_zip_postal") } }
  const customCountryMessages = { messages: { required: I18n.t("sign_up.validation.required_country") } }

  return (
    // note: accept="accept=image/*"  <--- I had to enable everything in order for heic/heif files to show up
    <div>
      <div className="flex">
        <h2 className="purple capitalize bold mb2 mt2">{I18n.t("sign_up.three.profile")}</h2>
        <img
          src={Info}
          className="self-center ml1"
          alt="React Logo"
          style={{ height: 27 }}
          data-tip={I18n.t("sign_up.three.upload_info")}
          data-for="upload-label"
        />
      </div>
      <ReactTooltip place="bottom" id="upload-label" />
      <div className="flex flex-row justify-space-evenly">
        <div>
          <ImageUploader
            buttonText={I18n.t("sign_up.three.upload_photo")}
            defaultImages={user.pictureUrl ? [user.pictureUrl] : []}
            withIcon={false}
            singleImage={true}
            onChange={onDrop}
            imgExtension={['.jpg', '.jpeg', '.png']}
            maxFileSize={5242880}
            buttonStyles={{ backgroundColor: '#62BB47' }}
            className="Upload"
            buttonClassName=""
            withPreview={true}
            withLabel={false}
          />
        </div>
        <div className="Field ml2">
          <label htmlFor="bio">{I18n.t("sign_up.three.tell_us_about_yourself")}</label>
          <textarea
            id="bio"
            name="user[bio]"
            value={user.bio}
            rows="5"
            cols="33"
            onChange={(e) => setUser({ ...user, bio: e.target.value })}
          />
        </div>

      </div>
      <div className="Field mt2">

        <label htmlFor="year">{I18n.t("sign_up.three.year_joined_ff")}</label>
        <select id={`year_joined`} name={`year_joined]`}
          className="form-control"
          onChange={(e) => { setUser({ ...user, yearJoined: e.target.value }) }}
          defaultValue={user.yearJoined}
        >
          <option>{I18n.t("sign_up.three.select_a_year")}</option>
          {
            years.map((year, i) => <option key={i} value={year}>{year}</option>)
          }
        </select>
      </div>
      <div className="mt2">
        <div className="flex">
          <h3 className="purple capitalize bold h2 mt3 mb3">{I18n.t("sign_up.three.your_address")}</h3>
          <img
            src={Info}
            className="self-center ml1"
            alt="React Logo"
            style={{ height: 27 }}
            data-tip={I18n.t("sign_up.three.address_info")}
          />
          <ReactTooltip place="right" />
        </div>
        <div className="Field">

          <label htmlFor="street1">{I18n.t("sign_up.three.street_address")}</label>
          <input type="text" id="street1" name="street1"
            value={user.address.street1}
            onChange={(e) => setUser({ ...user, address: { ...user.address, street1: e.target.value } })}
          />
          <input className="mt2" type="text" id="street2" name="street2"
            value={user.address.street2}
            onChange={(e) => setUser({ ...user, address: { ...user.address, street2: e.target.value } })}
          />
        </div>
        <div className="flex flex-row">
          <div className="Field mt2 mr1 flex-basis-100">
            <label htmlFor="city">{I18n.t("sign_up.three.city")}</label>
            <input type="text" id="city" name="city"
              value={user.address.city}
              onChange={(e) => setUser({ ...user, address: { ...user.address, city: e.target.value } })}
            />
          </div>
          <div className="Field mt2 mr1 flex-basis-100">
            <label htmlFor="state_province">{I18n.t("sign_up.three.state_province")}</label>
            <input type="text" id="state_province" name="state_province"
              value={user.address.stateProvince}
              onChange={(e) => setUser({ ...user, address: { ...user.address, stateProvince: e.target.value } })}
            />
          </div>
          <div className="Field mt2 flex-basis-100">
            <label htmlFor="postal_code">{I18n.t("sign_up.three.zip_postal_code")}</label>
            <input type="text" id="postal_code" name="postal_code"
              value={user.address.postalCode}
              onChange={(e) => setUser({ ...user, address: { ...user.address, postalCode: e.target.value } })}
            />
          </div>
        </div>
        <div className="Field mt2">
          <label htmlFor="country_other">{I18n.t("sign_up.three.country")}</label>
          <input
            type="text"
            id="country_other"
            name="country_other"
            value={user.address.countryOther}
            readOnly
          />
        </div>
      </div>
      <div className="mt2">
        <div className="flex">
          <h3 className="purple capitalize bold h2 mt3 mb3">{I18n.t("sign_up.three.your_phone_number")} {I18n.t("sign_up.three.optional")}</h3>
        </div>
        <div className="flex flex-row">
          <div className="Field mr1 width33">
            <label>{I18n.t("sign_up.three.country_code")} </label>
            <input
              type="tel"
              name="user[country_code]"
              value={user.countryCode}
              onChange={(e) => setUser({ ...user, countryCode: e.target.value })}
            />
          </div>
          <div className="Field width67">
            <label>{I18n.t("sign_up.three.phone")}</label>
            <input
              type="tel"
              name="user[phone]"
              value={user.phone}
              onChange={(e) => setUser({ ...user, phone: e.target.value })}
            />
          </div>
        </div>
      </div>

      <div className="mb4 flex">
        <div onClick={() => setStep(2)} className="width90 mt5 Button bc-gray white uppercase mr1">{I18n.t("sign_up.one.previous")}</div>
        <div onClick={submitPageThree} className="width100 mt5 Button bc-teal white uppercase">{I18n.t("sign_up.one.continue")}</div>
      </div>
    </div >
  )
}
