document.addEventListener('turbolinks:load', function() {
  calendarModalListeners();
  calendarEventslisteners();
  announcementslisteners();
  journeyModalListeners();
})

$(document).on('ajax:complete', function() {
  calendarModalListeners();
  calendarEventslisteners();
  journeyModalListeners();
});

function journeyModalListeners() {
  $('.journey-link').off().on('click', function() {
    toggleModal($('#show-journey-modal'))
  })
  if ($('.hide-journey-button').length > 0) {
    $('.hide-journey-button').off().on('click', function() {
      toggleModal($('#show-journey-modal'))
    })
  }
}

function calendarModalListeners() {
  $('.event-link').off().on('click', function() {
    toggleModal($('#show-event-modal'))
  })
  if ($('.hide-event-button').length > 0) {
    $('.hide-event-button').off().on('click', function() {
      toggleModal($('#show-event-modal'))
    })
  }
}

function calendarEventslisteners() {
  $('.events-link').off().on('click', function() {
    toggleModal($('#events-club-modal'))
  })
  if ($('.leave-event-button').length > 0) {
    $('.leave-event-button').off().on('click', function() {
      toggleModal($('#events-club-modal'))
    })
  }
}

function announcementslisteners() {
  $('.show-announcement-modal').off().on('click', function() {
    toggleModal($('#announcement-modal'))
  })
  if ($('.leave-announcement-button').length > 0) {
    $('.leave-announcement-button').off().on('click', function() {
      toggleModal($('#announcement-modal'))
    })
  }
}

function toggleModal(modal) {
  if (modal.css('display') == 'block') {
    modal.css('display', 'none')
  } else {
    modal.css('display', 'block')
  }
}