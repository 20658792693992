import { froalaKey } from 'custom/froala_key.js.erb';

document.addEventListener('turbolinks:load', function() {
  //console.log('key : ' + froalaKey());
  const licenseKey = froalaKey()
  new FroalaEditor('#froala-text-area', {
    key: licenseKey,
    attribution: false,
    fileAllowedTypes: ['application/pdf'],
    fileUploadMethod: 'POST',
    fileUploadParams: {
      authenticity_token: $("meta[name='csrf-token']")[0].content
    },
    fileUploadURL: '/upload_file',
    imageUploadMethod: 'POST',
    imageUploadParams: {
      authenticity_token: $("meta[name='csrf-token']")[0].content
    },
    imageUploadURL: '/upload_file',
    events: {
      'image.removed': function($img) {
        console.log('file uploaded');
        $.post('/delete_file', {
          froala_slug: $img.attr('data'),
          authenticity_token: $("meta[name='csrf-token']")[0].content,
        });
      },
      'commands.undo': function() {
        // if we are asked to implement undoing deleting files, this is the event we will use for the code
        console.log('undo pressed, this: ' + this);
      }
    }
  });
});
