import { useStorageState } from 'react-storage-hooks';

export function usePersistentState(...args) {
  return useStorageState( sessionStorage, ...args);
}

export function clearPersistentStorage() {
  sessionStorage.removeItem('ffi-state-forceUpdate');
  sessionStorage.removeItem('ffi-state-forceUpdateTwo');
  sessionStorage.removeItem('ffi-state-forceUpdateThree');
  sessionStorage.removeItem('ffi-state-forceUpdateFour');

  sessionStorage.removeItem('ffi-state-emailExists');
  sessionStorage.removeItem('ffi-state-step');
  sessionStorage.removeItem('ffi-state-checkbox');
  sessionStorage.removeItem('ffi-state-pictures');

  sessionStorage.removeItem('ffi-state-user');
  sessionStorage.removeItem('ffi-state-passInfo');
  sessionStorage.removeItem('ffi-state-passwordShown');
  sessionStorage.removeItem('ffi-state-passwordConfirmationShown');
  sessionStorage.removeItem('ffi-state-long');
  sessionStorage.removeItem('ffi-state-number');
  sessionStorage.removeItem('ffi-state-lowecase');
  sessionStorage.removeItem('ffi-state-uppercase');
}

export function initIdleTimer() {
  let idleTime;
  window.onload = resetIdleTimer;
  document.onmousemove = resetIdleTimer;
  document.onkeypress = resetIdleTimer;
  document.onmousedown = resetIdleTimer; // touchscreen presses
  document.ontouchstart = resetIdleTimer;
  document.onclick = resetIdleTimer;     // touchpad clicks
  document.onkeydown = resetIdleTimer;   // onkeypress is deprectaed
  document.addEventListener('scroll', resetIdleTimer, true); // improved; see comments  

  function timerEndReached() {
    clearPersistentStorage();
    const numberCircles = document.getElementsByClassName('NumberCircle');
    const signUpDiv = document.getElementsByClassName('SignUp');
    if (numberCircles!=null && numberCircles.length > 0 && signUpDiv!=null && signUpDiv.length > 0) {
      location.href = '/users/sign_in?sign_up_expired=true';
    }
  }

  function resetIdleTimer() {
    // 1000 milliseconds = 1 second
    const maxIdleTime = 1000 * 60 * 15; // 15 minutes for Sign Up session
    clearTimeout(idleTime);
    idleTime = setTimeout(timerEndReached, maxIdleTime)
  }

};