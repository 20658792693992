document.addEventListener('turbolinks:load', function() {
  passwordVisibilityListeners();
});

document.addEventListener('partial:complete', function() {
  passwordVisibilityListeners();
})


function passwordVisibilityListeners() {
  if ($('#edit_user').length != 0) {
    $('#update-current-password svg').click(function() {
      togglePasswordVisibility($('#update-current-password input'));
    });
    $('#update-new-password svg').click(function() {
      togglePasswordVisibility($('#update-new-password input'));
    });
    $('#update-confirm-password svg').click(function() {
      togglePasswordVisibility($('#update-confirm-password input'));
    });
  }

  if ($('#new_user').length != 0) {
    $('#new-password-reset svg').click(function() {
      togglePasswordVisibility($('#new-password-reset input'));
    });
    $('#new-password-reset-confirm svg').click(function() {
      togglePasswordVisibility($('#new-password-reset-confirm input'));
    });
    $('#login_password svg').click(function() {
      togglePasswordVisibility($('#user_password'));
    });
  }
}

function togglePasswordVisibility(input) {
  if (input.attr('type') == 'password') {
    input.attr('type', 'text');
  } else {
    input.attr('type', 'password');
  }
}
