// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels");
require('custom/i18_locale.js.erb');
require('custom/froala_key.js.erb');
require('custom/chrome');
require('custom/admin_users');
require('custom/devise');
require('custom/dashboard');
require('custom/polyfill');
require('custom/home');
require('custom/calendar');
require('custom/google_translation');
require('custom/froala');
require("selectize")
require("custom/selectize")
require('custom/events');
require('custom/clubs');
require('custom/sortable_gallery_images');

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

// i18n for front-end
import I18n from "i18n-js";
global.I18n = I18n;

// jquery
import $ from 'jquery'
global.$ = $
global.jQuery = $

require("jquery")
require("trix")
require("@rails/actiontext")

import Sortable from 'sortablejs';


// Froala
import FroalaEditor from 'froala-editor/js/froala_editor.min.js';
global.FroalaEditor = FroalaEditor;
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/code_view.min.js';
import 'froala-editor/js/plugins/code_beautifier.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/draggable.min.js';
import 'froala-editor/js/plugins/file.min.js';
//import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/help.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/line_breaker.min.js';
import 'froala-editor/js/plugins/line_height.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/print.min.js';
import 'froala-editor/js/plugins/quote.min.js';
import 'froala-editor/js/plugins/table.min.js';

import 'froala-editor/js/third_party/image_tui.min.js';



