import React from 'react'

export default function Step({ number, step, submitPage, fillStepTwo, fillStepThree }) {
  let fillColor;
  if (step === 2) {
    fillColor = fillStepTwo
  } else if (step === 3) {
    fillColor = fillStepThree
  }
  return (
    <div
      className="NumberCircle mr2"
      onClick={() => submitPage()}
    >
      <p style={fillColor}>
        {number}
      </p>
    </div>

  )
}
