import React, { useState } from 'react';
import SelectDropdown from './SelectDropdown';
import GenericModal from './GenericModal';
import { showCodeOfConductModal } from './CodeOfConduct';
import { showTermsAndConditionsModal } from './TermsAndConditions';

export default function SignupFinal({
  setUser,
  user,
  checked,
  setCheckbox,
  setStep,
  languages,
  validator,
  submitForm
}) {

  function openModal(name) {
    if (name === 'conduct') {
      showCodeOfConductModal()
      //test
    }
    else {
      showTermsAndConditionsModal()
    }
  }

  let buttonColor = 'bc-gray'
  if (checked.terms && checked.conduct) {
    buttonColor = 'bc-green'
  }
  const customLanguageMessages = { messages: { required: I18n.t("sign_up.validation.required_communication_language") } }

  return (
    <div>
      <h2 className="purple capitalize bold mb2 mt2">{I18n.t("sign_up.four.profile_continued")}</h2>
      <div className="black uppercase"><p>{I18n.t("sign_up.four.your_social_media")}</p></div>
      <div>
        <div className="Field mt1">
          <input
            type="checkbox"
            name="facebook"
            value="Facebook"
            className="mr1"
            checked={checked.facebook}
            onChange={() => setCheckbox({ ...checked, facebook: !checked.facebook })} />
          <label
            className="!capitalize"
            htmlFor="facebook"
            onClick={() => setCheckbox({ ...checked, facebook: !checked.facebook })}
          >{I18n.t("sign_up.four.facebook")}</label>
          {
            checked.facebook &&
            <input
              type="text"
              placeholder="https://facebook.com/ProfileUrl"
              name="user[facebook]"
              value={user.facebook}
              onChange={(e) => setUser({ ...user, facebook: e.target.value })}
            />
          }
        </div>
        <div className="Field mt1">
          <input
            type="checkbox"
            name="instagram"
            value="instagram"
            className="mr1"
            checked={checked.instagram}
            onChange={() => setCheckbox({ ...checked, instagram: !checked.instagram })} />
          <label
            className="!capitalize"
            htmlFor="instagram"
            onClick={() => setCheckbox({ ...checked, instagram: !checked.instagram })}
          >{I18n.t("sign_up.four.instagram")}</label>
          {
            checked.instagram &&
            <input
              type="text"
              placeholder="@"
              name="user[instagram]"
              value={user.instagram}
              onChange={(e) => setUser({ ...user, instagram: e.target.value })}
            />
          }
        </div>
        <div className="Field mt1" >
          <input
            type="checkbox"
            name="user[twitter]"
            value="twitter"
            className="mr1"
            checked={checked.twitter}
            onChange={() => setCheckbox({ ...checked, twitter: !checked.twitter })}
          />
          <label
            htmlFor="twitter"
            className="!capitalize"
            onClick={() => setCheckbox({ ...checked, twitter: !checked.twitter })}
          >{I18n.t("sign_up.four.twitter")}</label>
          {
            checked.twitter &&
            <input
              type="text"
              name="user[twitter]"
              placeholder="@"
              value={user.twitter}
              onChange={(e) => setUser({ ...user, twitter: e.target.value })}
            />
          }
        </div>
        <div className="Field mt1" >
          <input
            type="checkbox"
            name="user[whatsapp]"
            value="whatsapp"
            className="mr1"
            checked={checked.whatsapp}
            onChange={() => setCheckbox({ ...checked, whatsapp: !checked.whatsapp })}
          />
          <label
            htmlFor="whatsapp"
            className="!capitalize"
            onClick={() => setCheckbox({ ...checked, whatsapp: !checked.whatsapp })}
          >{I18n.t("sign_up.four.whatsapp")}</label>
          {
            checked.whatsapp &&
            <input
              type="text"
              name="user[whatsapp]"
              placeholder="#"
              value={user.whatsapp}
              onChange={(e) => setUser({ ...user, whatsapp: e.target.value })}
            />
          }
        </div>
      </div>
      <SelectDropdown
        list={languages}
        name="language"
        setUser={setUser}
        user={user}
        labelText={I18n.t("sign_up.four.communication_language_preference")}
        validator={validator}
        options={customLanguageMessages}
      />
      <div className="Field mt2" >
        <input
          type="checkbox"
          name="user[email_preference_attributes][opt_in]"
          className="mr1"
          value={user.emailPreference.optIn}
          checked={user.emailPreference.optIn}
          onChange={() => setUser({ ...user, emailPreference: { ...user.emailPreference, optIn: !user.emailPreference.optIn }})}
        />
        <label
          htmlFor="opt_in"
          className="!no-uppercase"
          onClick={() => setUser({ ...user, emailPreference: { ...user.emailPreference, optIn: !user.emailPreference.optIn }})}
        >
          {I18n.t("sign_up.four.opt_in_mailing_list")}
        </label>
      </div>
      {
        user.languageName === 'English' || user.languageName === 'Choose a language' || user.languageName === '' ? null : (
          <div className="Field mt2" >
            <input
              type="checkbox"
              name="user[email_preference_attributes][also_english]"
              id="also_english"
              className="mr1"
              value={user.emailPreference.alsoEnglish}
              checked={user.emailPreference.alsoEnglish}
              onChange={() => setUser({ ...user, emailPreference: { ...user.emailPreference, alsoEnglish: !user.emailPreference.alsoEnglish }})}
            />
            <label
              htmlFor="also_english"
              className="!no-uppercase"
              onClick={() => setUser({ ...user, emailPreference: { ...user.emailPreference, alsoEnglish: !user.emailPreference.alsoEnglish }})}
            >
              {I18n.t("sign_up.four.receive_communique_in_english")}
            </label>
          </div>)
      }
      <div className="mt4 accept-both">{I18n.t("sign_up.four.accept_both")}</div>
      <div className="Field mt0">
        <input
          type="checkbox"
          // name="user[terms]"
          className="mr1"
          value="terms"
          checked={checked.terms}
          onChange={() => setCheckbox({ ...checked, terms: !checked.terms })}
        />
        <label
          htmlFor="terms"
          className="!no-uppercase"
          onClick={() => openModal('terms')}
        >
          {I18n.t("sign_up.four.terms_and_conditions_pre_phrase")}
          <em
            className="gray underline ml1">{I18n.t("sign_up.four.terms_and_conditions_phrase")}</em>
          &nbsp;{I18n.t("sign_up.four.terms_and_conditions_post_phrase")}
        </label>
      </div>
      <div className="Field mt2">
        <input
          type="checkbox"
          value="conduct"
          className="mr1"
          checked={checked.conduct}
          onChange={() => setCheckbox({ ...checked, conduct: !checked.conduct })}
        />
        <label
          htmlFor="conduct"
          className="!no-uppercase"
          onClick={() => openModal('conduct')}
        >
          {I18n.t("sign_up.four.code_of_conduct_pre_phrase")}
          <em
            className="gray underline ml1">{I18n.t("sign_up.four.code_of_conduct_phrase")}</em>
          &nbsp;{I18n.t("sign_up.four.code_of_conduct_post_phrase")}
        </label>
      </div>
      <div className="mb4 flex">
        <div onClick={() => setStep(3)} className="width90 mt5 Button bc-gray white uppercase mr1">{I18n.t("sign_up.one.previous")}</div>
        <input
          className={`width90 mt5 Button white uppercase ${buttonColor}`}
          type="submit" name="commit"
          value={I18n.t("sign_up.four.sign_up")}
          disabled={!checked.terms}
          onClick={(e) => submitForm(e)}
        />
      </div>      
      <GenericModal />
    </div>
  )
}
