import React, { useState, useEffect } from 'react';
import TableRow from './TableRow'
import Modal from "react-modal";

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: '100'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: "80%",
    height: "670px",
    overflow: "auto",
    display: 'flex',
    justifyContent: 'center'
  }
};

function UserApprovals({ users, currentUser }) {
  const [email, setEmail] = useState({
    user: {},
    userStatus: "",
    subject: "",
    body: "",
  });

  function createBodyTemplate(user, status) {
    return ``
  };

  const [modalIsOpen, setIsOpen] = useState(false);
  const [usersList, setUsers] = useState(users);

  useEffect(() => {
  }, [usersList]);

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#f00";
  }

  function handleClick(user, newStatus) {
    if (newStatus == 'SUSPENDED' || newStatus == 'REJECTED' || newStatus == 'GENERIC') {

      const templateEmailBody = createBodyTemplate(user, newStatus)
      var subject;
      if (newStatus == 'SUSPENDED') {
        subject = I18n.t("admin_approval.email.subject_suspended")
      }
      else if (newStatus == 'REJECTED') {
        subject = I18n.t("admin_approval.email.subject_rejected")
      }
      else if (newStatus == 'GENERIC') {
        subject = I18n.t("admin_approval.email_subject_generic")
      }
      const templateEmailSubject = `${subject}`
      setEmail({
        ...email,
        userStatus: newStatus,
        user: user,
        body: templateEmailBody,
        subject: templateEmailSubject
      });
      openModal();
      return;
    }
    handleRequest(user, newStatus);
  }

  function handleRequest(user, status) {
    const token = document.querySelector('[name=csrf-token]').content
    let data = { status: status }
    if (email.userStatus) {
      data = { ...data, subject: email.subject, body: email.body }
    }
    fetch(`/clubs/${user.club_id}/update-member-status/${user.id}`, {
      method: 'PATCH',
      headers: {
        'X-CSRF-Token': token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then((data) => data.text())
      .then((res) => {
        const usersResponse = JSON.parse(res)
        const updateUserstemp = users.map(user => {
          if (user.id === usersResponse.user.id) {
            user.status = usersResponse.user.status
          }
          return user;
        })
        var updateUsers;
        if (status == 'APPROVED'){
          updateUsers = updateUserstemp.filter((user) => user.status !== 'REJECTED' && user.status !== 'SUSPENDED');
        }else{
          updateUsers = updateUserstemp.filter((user) => user.id !== usersResponse.user.id && user.status !== 'REJECTED' && user.status !== 'SUSPENDED');  
        }
        
        setUsers(updateUsers);
        modalIsOpen && closeModal()
      });
  }

  function sortColumn(column, position) {
    tbody = $('.members-table');
    var columnOrder;

    if (column == 'name') {
      columnOrder = $('.member-name-order');
    } else if (column == 'member-id') {
      columnOrder = $('.member-id-order');
    }  else if (column == 'deceased') {
      columnOrder = $('.member-deceased-order');
    }else {
      columnOrder = $('.member-email-order');
    }

    tbody.find('tr').sort(function(a, b) {
      if (columnOrder.val() == 'asc') {
        return $('td:eq(' + position + ')', b).text().localeCompare($('td:eq(' + position + ')', a).text());
      } else {
       return $('td:eq(' + position + ')', a).text().localeCompare($('td:eq(' + position + ')', b).text());
      }
    }).appendTo(tbody);
    setOrderValue(columnOrder);
  }

  function setOrderValue(columnOrder) {
    if (columnOrder.val() == 'asc') {
      columnOrder.val('desc');
    } else {
      columnOrder.val('asc');
    }
  }

  return (
    <section className="main-content__body main-content__body--flush width100 ClubTable">
      <table aria-labelledby="page-title">
        <thead>
          <tr className="js-table-row members-header">
            <th onClick={() => sortColumn('name', '0')} className="members-name-header darkgray pointer">{I18n.t("member_management.label_member_name")}</th>
            <th onClick={() => sortColumn('member-id', '1')} className="darkgray pointer">{I18n.t("member_management.label_member_id")}</th>
            <th onClick={() => sortColumn('email', '2')} className="darkgray pointer">{I18n.t("member_management.label_member_email")}</th>
            <th onClick={() => sortColumn('deceased', '3')} className="darkgray pointer">{I18n.t("member_management.label_member_deceased")}</th>
          </tr>
        </thead>
        <tbody className="members-table">
          {
            usersList ? usersList.map((user) => {
              return (
                <TableRow
                  user={user}
                  key={user.id}
                  setUsers={setUsers}
                  currentUser={currentUser}
                  handleRequest={handleRequest}
                  handleClick={handleClick}
                />
              )
            }) : <h3>Loading...</h3>
          }
        </tbody>
      </table>
      <Modal
        isOpen={modalIsOpen}
        ariaHideApp={false}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Sign Up Modal"
      >
        <div className="width80">
          <h2 className="purple h2 mb4 mt4">
            {
              email.userStatus == 'REJECTED' &&
              I18n.t("admin_approval.email.template_rejection_title") + ": " + email.user.email
            }
            {
              email.userStatus == 'SUSPENDED' &&
              I18n.t("admin_approval.email.template_suspension_title") + ": " + email.user.email
            }
            {
              email.userStatus == 'GENERIC' &&
              I18n.t("admin_approval.email.template_generic_title") + ": " + email.user.email
            }

          </h2>
          <div className="Field mt2">
            <label htmlFor="email_body">
              {
                email.userStatus == 'REJECTED' &&
              //  <p> {I18n.t("admin_approval.email.template_rejection_label")} <span className="no-transform underline">{I18n.t("admin_approval.email.template_rejection_label_reason")}</span> <span className="no-transform">{I18n.t("admin_approval.email.template_rejection_label_contact")}</span></p>
              <p> {I18n.t("are_you_sure_continue")} <span className="no-transform">{I18n.t("admin_approval.email.template_rejection_label_contact")}</span></p>
              }
              {
                email.userStatus == 'SUSPENDED' &&
                // I18n.t("admin_approval.email.template_suspension_label")
                I18n.t("are_you_sure_continue")
              }
              {
                email.userStatus == 'GENERIC' &&
                I18n.t("admin_approval.email.template_generic_label")
              }
            </label>
            {/* <textarea
              id="email_body"
              name="email_body"
              rows="15"
              cols="33"
              value={email.body}
              onChange={(e) => setEmail({ ...email, body: e.target.value })}
            /> */}
          </div>
          <div className="flex justify-space-around">
            <div onClick={() => handleRequest(email.user, email.userStatus)} className="width50 mt5 mr1 Button bg-teal white uppercase">{ I18n.t("sign_up.four.submit") }</div>
            <div onClick={closeModal} className="width50 mt5 ml1 Button bc-gray white uppercase">{ I18n.t("cancel") }</div>
          </div>
        </div>
      </Modal>
      <input type="hidden" className="member-name-order" name="member-name-order" value="asc" />
      <input type="hidden" className="member-id-order" name="member-id-order" value="desc" />
      <input type="hidden" className="member-email-order" name="member-email-order" value="desc" />
      <input type="hidden" className="member-deceased-order" name="member-deceased-order" value="desc" />
    </section>
  )
}

export default UserApprovals;
