import React from 'react'

// only applies to Language now. Country and Club are using react-select-search
const SelectDropdown = ({ list, name, setUser, user, labelText, validator, options = {} }) => {
  let message = '';
  let chooseLabel = 'Choose a ' + name
  if (name === 'club') {
    chooseLabel = I18n.t("sign_up.two.choose_a_club")
  }
  else if (name === 'country') {
    chooseLabel = I18n.t("sign_up.two.choose_a_country")
  }
  else if (name === 'language') {
    chooseLabel = I18n.t("sign_up.two.choose_a_language")
  }

  if (!user.country && name === 'club') {
    message = <span className='ml2'>{I18n.t("sign_up.two.choose_a_country_first")}</span>;
  } else if (list.length < 1 && name === 'club') {
    message = <span className='ml2'>{I18n.t("sign_up.two.no_clubs_found")}</span>;
  } else {
    message = null;
  }

  // Convert selected number to validate is an id.
  function handleChange(e) {
    const value = Number(e.target.value) ? e.target.value : ''
    const text = Number(e.target.value) ? e.target.selectedOptions[0].text : ''
    setUser({ ...user, [name]: value, languageName: text })
  }
  return (
    <div className="Field mt3">
      <label htmlFor={`${name}`}>{labelText}</label>
      <span style={{ color: 'red', display: 'inline', float: 'none' }} >*</span>
      {message}
      <select id={`${name}`} name={`user[${name}_id]`}
        className="form-control"
        onChange={(e) => { handleChange(e) }}
        defaultValue={user[name]}
      >
        <option>{chooseLabel}</option>
        {
          list.map((option) => (

            <option key={option.id} value={option.id}>{option.display_name}</option>
          ))
        }
      </select>
      {validator ? validator.current.message(`field`, user[name], 'required',
        options) : null }

    </div >

  );
}

export default SelectDropdown;
