import I18n from 'i18n-js';
import { initializeGenericModal, p, div, span, a, ol, li } from './GenericModal';

// NOTE: the modal launch code is within the React code SignupFinal.jsx
export function showTermsAndConditionsModal() {
  initializeGenericModal()

  let modal = document.getElementById("generic-modal")
  if (modal!=null) {
    let content = modal.querySelector(".modalContent")
    if (content!=null) {
      content.innerHTML = generateContent()
      content.style.display="block"
    }
    modal.style.display = "block"
  }
}

// nothing fancy: just builds text line by line using shorthand methods that mimic the html structure
function generateContent() {

  var body = p("pt2", span("title", I18n.t("terms_and_conditions.title_html")))
  body += p("pt2", I18n.t("terms_and_conditions.intro_p1_html"))

  body += p("pt3 pb1", I18n.t("terms_and_conditions.intro_p2_html"))
  var headingListitems = li(null, I18n.t("terms_and_conditions.intro_p2_li1_html")) + 
              li(null, I18n.t("terms_and_conditions.intro_p2_li2_html")) +
              li(null, I18n.t("terms_and_conditions.intro_p2_li3_html"))
  body += ol("decimalRightParen", "1", headingListitems)

  var heading1Items = li("subtitle", I18n.t("terms_and_conditions.s1_title_html"))
  body += p("pt4", ol("decimalRightPeriod", "1", heading1Items))
  body += p("pt0", I18n.t("terms_and_conditions.s1_p1_html"))

  var s1Link = span("blueLink", a(null, "https://www.google.com/url?q=http://www.friendshipforce.org/&sa=D&ust=1601500079550000&usg=AOvVaw2GHvR2xdzDiP8ulOwVV9CU", "_blank", I18n.t("terms_and_conditions.s1_p2_link_html")))
  
  var s1LinkSection = span("bold", I18n.t("terms_and_conditions.s1_p2_header_html")) + 
                      I18n.t("terms_and_conditions.s1_p2_pre_html") + 
                      s1Link +
                      I18n.t("terms_and_conditions.s1_p2_post_html")
                      
  body += p("pt2", s1LinkSection)
  body += p("pt2", span("bold", I18n.t("terms_and_conditions.s1_p3_header_html")) + I18n.t("terms_and_conditions.s1_p3_html"))

  var s1Items = li("pb2", I18n.t("terms_and_conditions.s1_p3_li1_html")) + 
                li("pb2", I18n.t("terms_and_conditions.s1_p3_li2_html")) +
                li("pb2", I18n.t("terms_and_conditions.s1_p3_li3_html"))
    
  body += p("pt2", ol("romanLeftRightParen", "1", s1Items))

  var s1Link2 = span("blueLink", a(null, "https://www.google.com/url?q=https://blog.friendshipforce.org/virtual-experiences/&amp;sa=D&amp;ust=1601500079551000&amp;usg=AOvVaw1mI2z94HIQhUJjglo7jY8u", "_blank", I18n.t("terms_and_conditions.s1_p4_link_html")))
  var s2Link2Section = I18n.t("terms_and_conditions.s1_p4_pre_html") + 
                        s1Link2 +
                        I18n.t("terms_and_conditions.s1_p4_post_html")
  body += p("pt0", s2Link2Section)
                      
  body += p("pt4", ol("decimalRightPeriod", "2", li("subtitle", I18n.t("terms_and_conditions.s2_title_html"))))
  body += p("pt0", I18n.t("terms_and_conditions.s2_p1_html"))
  body += p("pt2", I18n.t("terms_and_conditions.s2_p2_html"))
  body += p("pt2", I18n.t("terms_and_conditions.s2_p3_html"))

  body += p("pt4", ol("decimalRightPeriod", "3", li("subtitle", I18n.t("terms_and_conditions.s3_title_html"))))
  body += p("pt0", I18n.t("terms_and_conditions.s3_p1_html"))

  var link = span("blueLink", a(null, "mailto:support@friendshipforce.org", "_blank", I18n.t("terms_and_conditions.s3_p2_link_html")))
  var sectionWithLink = I18n.t("terms_and_conditions.s3_p2_pre_html") + link + I18n.t("terms_and_conditions.s3_p2_post_html")
  body += p("pt2", sectionWithLink)

  var content = div("p2 flex flex-column self-center Terms", body)
  return content
}




