document.addEventListener('turbolinks:load', function() {
  var userClubSelectized = (document.getElementById('user_club_id') !== null);
  passwordChangeListeners();
  usereditAdminListeners();

  function passwordChangeListeners() {
    passwordChangeFormcstm = $('#passwrd_rst_tst_btn')
    adminpasswordChangeFormcstm = $('#reset_password_manually_tst_btn')

    if (passwordChangeFormcstm.length === 1) {
      passwordChangeFormcstm.on('click', function(event){
        event.preventDefault();
        if (confirm('Are you sure want to send reset password mail?')) {
          $('#passwrd_rst_tst_btn').unbind('click').click(); 
          $("#flash_section").html('<div class="flashes"><div class="flash flash-notice">Mail Sent successfully.</div></div>');
          setTimeout(function(){$("#flash_section").html('')}, 3000);  
        }
        
      });
    }

    if (adminpasswordChangeFormcstm.length === 1) {
      adminpasswordChangeFormcstm.on('click', function(event){
        event.preventDefault();
        var checkpass = false;
        var password_val = $("#mnl_password").val();
        var confirm_password_val = $("#mnl_confirm_password").val()
        var mnl_psswrd_error;
        console.log('password_val'+password_val);
        console.log('confirm_password_val'+confirm_password_val);
        var error = '0';
        
        if(password_val.length < 6){
          mnl_psswrd_error = 'Minimum Password length must be 6';
          error++
        }
        
        if (password_val != confirm_password_val){
          mnl_psswrd_error = 'Both password must be same';
          error++
        }
        if (error == '0'){
          checkpass = true;
        }

        if (checkpass) {
          // console.log('form sent');
          $('#reset_password_manually_tst_btn').unbind('click').click(); 
        }else{
          // console.log('form not sent');
          $("#flash_section").html('<div class="flashes"><div class="flash flash-error">'+mnl_psswrd_error+'</div></div>');
        }
        
      });
    }

  }

  function usereditAdminListeners() {
    usereditadminFormcstm = $('.custom_edit_form_user')

    if (usereditadminFormcstm.length === 1) {
      currentMethodvalue = $("input[name='_method']").val();
      // console.log(currentMethodvalue);
      currentMethodvalue = $("input[name='_method']").val('patch');
      // console.log(currentMethodvalue);
      
    }

  }

  if ( userClubSelectized ) {
    userCountry = $('.field-unit__field.country');
    userCountrySelect = $('#user_country_id');
    userClub = $('.field-unit__field.club');
    userClubSelect = $('#user_club_id');

    userClub.on('click', filterClubDropdown);
    userCountry.on('click', filterClubDropdown);
    userCountrySelect[0].selectize.on('change', setClubUnaffiliated);
    userClubSelect[0].selectize.on('change', setCountryFromClub);

    filterClubDropdown();
  }

  function setClubUnaffiliated() {
    notAffClubId = $('.club-dropdown-option:contains("Not affiliated with a club")').data('value');
    userClubSelect[0].selectize.addItem(notAffClubId, true);
    filterClubDropdown();
  }

  function setCountryFromClub() {
    if (userClubSelect.val() !== "") {
      countryID = $('#field-club-id-' + userClubSelect.val()).attr('value').substring(11);
      userCountrySelect[0].selectize.addItem(countryID, true);
      filterClubDropdown();
    }
  }

  function filterClubDropdown() {
    userClubSelect[0].selectize.refreshOptions(false);
    $('.field-unit__field.club .selectize-dropdown-content .option').addClass('club-dropdown-option');

    $('.club-dropdown-option').each(function(index, item) {
      if (userCountrySelect.val() !== '') {
        $(item).css("display", "none");
      } else {
        $(item).css("display", "block");
      }
      if ( $('#field-club-id-' + $(item).data('value')).attr('value') == 'country-id-' + userCountrySelect.val() ) {
        $(item).css("display", "block");
      } else if ($(item).text() == 'Not affiliated with a club') {
        $(item).css("display", "block");
      }
    });
  }
});
