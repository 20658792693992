document.addEventListener('turbolinks:load', function() {
  eventListListeners();
})

function eventListListeners() {
  
	var events = document.getElementsByClassName('event-toggle')
	if(events){
		[...events].forEach( event => {
			event.addEventListener('click', function() {
        console.log(event.nextElementSibling.style.display == "")
        if (event.nextElementSibling.style.display == "none" || event.nextElementSibling.style.display == "") {
				  event.nextElementSibling.style.display = "block"
        } else {
          event.nextElementSibling.style.display = "none"
        }
			})
		})
	}
}