import { initializeGenericModal, p, div, span, a } from './GenericModal';

// NOTE: the modal launch code is within the React code SignupFinal.jsx
export function showCodeOfConductModal() {
  initializeGenericModal()

  let modal = document.getElementById("generic-modal")
  if (modal!=null) {
    let content = modal.querySelector(".modalContent")
    if (content!=null) {
      content.innerHTML = generateContent()
      content.style.display="block"
    }
    modal.style.display = "block"
  }
}

// nothing fancy: just builds text line by line using shorthand methods that mimic the html structure
function generateContent() {
  var body = p("pt2", span("title", I18n.t("code_of_conduct.title_html")))
  body += p("pt4", span("subtitle", I18n.t("code_of_conduct.subtitle_html")))
  
  body += p("pt3 pb1", I18n.t("code_of_conduct.s1_text_html"))

  body += p("pt2", span("italic", I18n.t("code_of_conduct.s1_header_html")))
  body += p("pt1", I18n.t("code_of_conduct.s1_text_html"))

  body += p("pt3", span("italic", I18n.t("code_of_conduct.s2_header_html")))
  body += p("pt1", I18n.t("code_of_conduct.s2_text_html"))

  body += p("pt3", span("italic", I18n.t("code_of_conduct.s3_header_html")))
  body += p("pt1", I18n.t("code_of_conduct.s3_text_html"))

  body += p("pt3", span("italic", I18n.t("code_of_conduct.s4_header_html")))
  body += p("pt1", I18n.t("code_of_conduct.s4_text_html"))

  body += p("pt3", span("italic", I18n.t("code_of_conduct.s5_header_html")))
  body += p("pt1", I18n.t("code_of_conduct.s5_text_html"))

  body += p("pt3", span("italic", I18n.t("code_of_conduct.s6_header_html")))
  body += p("pt1", I18n.t("code_of_conduct.s6_text_html"))

  body += p("pt4", I18n.t("code_of_conduct.s7_p1_html"))

  var link = span("blueLink", a(null, "mailto:support@friendshipforce.org", "_blank", I18n.t("code_of_conduct.s7_p2_link_html")))
  var sectionWithLink = I18n.t("code_of_conduct.s7_p2_pre_html") + link + I18n.t("code_of_conduct.s7_p2_post_html")
  body += p("pt2", sectionWithLink)
  
  var content = div("p2 flex flex-column self-center Terms", body)
  return content
}
