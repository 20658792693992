import React from 'react'
import CloseIcon from "../custom/closeIcon";

// ===============================================================================================
// There's a general issue of React not allowing html tags to be used within i18n translations
// I considered react modules to fix this, but those implementations required that the i18n text 
// be declared apart from the *.yml files. Keeping the i18n-js module was critical, which auto-generates 
// a js version of the i18n sources for javascript to use.
// The end solution was plain js to handle the modal versions of Terms and Conditions / Code of Conduct 
// (and any additional i18n text that needs to appear modally)
// ===============================================================================================

export default function GenericModal() {
  return (
    <div id="generic-modal" className="GenericModal">
      <div className="modal-content">
        <div className="ModalIconClose Pointer">
          <CloseIcon />
        </div>
        <div className="modalContent clubLineHeight mt0 mb4"></div>
        <div className="Button page-button uppercase white bold no-underline mt3 mb0">
          {I18n.t('close')}
        </div>
      </div>
    </div>
  )
}

// Three ways to close the modal
// - upper right big X
// - button at bottom of modal
// - click outside the modal
export function initializeGenericModal() {
  let modal = document.getElementById("generic-modal")
  if (modal==null) {
    console.log("modal not found")
    return
  }

  let content = modal.querySelector(".modalContent")
  if (content!=null) {
    content.innerHTML = ''
  }

  let close = modal.querySelector(".ModalIconClose")
  if (close!=null) {
    close.onclick = function() {
      modal.style.display = "none"
    }  
  }

  let closeButton = modal?.querySelector(".Button")
  if (closeButton!=null) {
    closeButton.onclick = function() {
      modal.style.display = "none"
    }  
  }

  window.onclick = function(event) {
    if (event.target == modal) {
      modal.style.display = "none"
    }
  }
  modal.style.display = "block"
}

export function htmlTag(tagName, classNames, attributeMap, innerHtml = null) {
  var html = "<" + tagName
  if (classNames!=null) {
    html += " class=\"" + classNames + "\""
  } 
  if (attributeMap!=null) {
    // doing the safe generic iteration
    Object.keys(attributeMap).forEach(function(key) {
      html += " " + key + "=\"" + attributeMap[key] + "\""
    })
  }
  html += ">"
  if (innerHtml!=null) {
    html += innerHtml
  }
  html += "</" + tagName + ">"
  return html
}

export function p(classNames, innerHtml) {
  return htmlTag("p", classNames, null, innerHtml)
}

export function div(classNames, innerHtml) {
  return htmlTag("div", classNames, null, innerHtml)
}

export function span(classNames, innerHtml) {
  return htmlTag("span", classNames, null, innerHtml)
}

export function ol(classNames, startOrder, innerHtml) {
  var attributeMap = null
  if ("startOrder" != null) {
    attributeMap = { "start" : startOrder }
  }
  return htmlTag("ol", classNames, attributeMap, innerHtml)
}

export function li(classNames, innerHtml) {
  return htmlTag("li", classNames, null, innerHtml)
}

export function a(classNames, href, target, innerHtml) {
  var attributeMap = { "href" : href }
  if ("target" != null) {
    attributeMap["target"] = target
  }
  return htmlTag("a", classNames, attributeMap, innerHtml)
}
