import React, { useState, useMemo, useEffect, useRef } from "react"
import SimpleReactValidator from 'simple-react-validator';
import Requirement from "./Requirement";
import Eye from '../images/jamicons/eye-f.svg';
import Alert from '../images/jamicons/alert.svg'
import { usePersistentState } from './PersistentStorage';

export default function SignupOne({
  user,
  setUser,
  validator,
  submitForm,
  step,
  emailExists,
}) {

  const customFirstNameMessages = { messages: { required: I18n.t("sign_up.validation.required_first_name") } }
  const customLastNameMessages = { messages: { required: I18n.t("sign_up.validation.required_last_name") } }
  const customEmailMessages = { messages: { required: I18n.t("sign_up.validation.required_email"), email: I18n.t("sign_up.validation.valid_email_address") } }
  const customPasswordMessages = { messages: { required: I18n.t("sign_up.validation.required_password"), regex: I18n.t("sign_up.validation.password_must_match_pattern") } }
  const customPasswordConfirmationMessages = { messages: { required: I18n.t("sign_up.validation.required_password_confirmation") } }

  const [showPassInfo, setPassInfo] = usePersistentState( 'ffi-state-passInfo', false);
  const [passwordShown, setPasswordShown] = usePersistentState( 'ffi-state-passwordShown', false);
  const [passwordConfirmationShown, setPasswordConfirmationShown] = usePersistentState( 'ffi-state-passwordConfirmationShown', false);
  const [long, longEnough] = usePersistentState( 'ffi-state-long', false);
  const [number, hasNumber] = usePersistentState( 'ffi-state-number', false);
  const [lowercase, hasLowercase] = usePersistentState( 'ffi-state-lowercase', false);
  const [uppercase, hasUppercase] = usePersistentState( 'ffi-state-uppercase', false);

  const deviseLinks = (
    <div className="text-center mt4 SignUp">
      <a href={'/' + I18n.locale + '/users/sign_in'}>{I18n.t("shared_links.have_an_account_login")}</a><br />
      <a className="mb2 Link" href={'/' + I18n.locale + '/users/confirmation/new'}>{I18n.t("shared_links.confirmation_instructions")}</a><br />
      <a className="mb2 Link" href={'/' + I18n.locale + '/users/unlock/new'}>{I18n.t("shared_links.unlock_instructions")}</a><br />
    </div>
  )
  function handleChange(e) {
    const matchedPassword = user.password === e.target.value
    setUser({ ...user, passwordConfirmation: e.target.value, passwordsMatch: matchedPassword })
  }

  function handlePassword(e) {
    const value = e.target.value;
    setUser({ ...user, password: value });

    const long = value.length < 6;
    const numberCheck = /.*\d/.test(value);
    const lowercaseCheck = /[a-z]/.test(value)
    const uppercaseCheck = /[A-Z]/.test(value)

    long ? longEnough(false) : longEnough(true);
    !numberCheck ? hasNumber(false) : hasNumber(true);
    !lowercaseCheck ? hasLowercase(false) : hasLowercase(true);
    !uppercaseCheck ? hasUppercase(false) : hasUppercase(true);
  }

  return (
    <>
      <div className="flex flex-row">
        <div className="Field mr1 flex-basis-100">
          <label>{I18n.t("sign_up.one.first_name")}</label> *
          <input
            type="text"
            name="user[first_name]"
            value={user.firstName}
            onChange={(e) => setUser({ ...user, firstName: e.target.value })}
            onBlur={() => validator.current.showMessageFor(I18n.t("sign_up.one.first_name"))}
            required
          />
          {validator.current.message(I18n.t("sign_up.one.first_name"), user.firstName, 'required', customFirstNameMessages)}
        </div>
        <div className="Field flex-basis-100">
          <label>{I18n.t("sign_up.one.last_name")}</label> *
          <input
            type="text"
            name="user[last_name]"
            value={user.lastName}
            onChange={(e) => setUser({ ...user, lastName: e.target.value })}
            onBlur={() => validator.current.showMessageFor(I18n.t("sign_up.one.last_name"))}
            required
          />
          {validator.current.message(I18n.t("sign_up.one.last_name"), user.lastName, 'required', customLastNameMessages)}
        </div>
      </div>
      <div className="Field mt2">
        <label>{I18n.t("sign_up.one.nickname")}</label>
        &nbsp;&nbsp;<em> ({I18n.t("sign_up.one.leave_blank_if_na")}) </em>
        <input
          type="text"
          name="user[nickname]"
          value={user.nickname}
          onChange={(e) => setUser({ ...user, nickname: e.target.value })}
        />
      </div>
      <div className="Field mt2">
        <label>{I18n.t("sign_up.one.email")}</label> *
        <input
          type="text"
          name="user[email]"
          value={user.email}
          onChange={(e) => setUser({ ...user, email: e.target.value })}
          onBlur={() => validator.current.showMessageFor(I18n.t("sign_up.one.email"))}
          required
        />
        {
          // TODO: Remove or reuse after we get tranlations
          // alert(I18n.t('sign_up.validation.valid_email_exists'))
          emailExists ?
            (<div>
              <img
                src={Alert}
                alt="Info"
                className="fill-blue"
                style={{ padding: '8px', alignSelf: 'center' }}
              />
              <p className="bold blue">
                This email address already exists.
            Please go to the member sign-in page <a href="/en/users/sign_in">here</a> and
            click on <em>'Forgot Password?'</em> to reset your credentials.
            </p>
            </div>) : null
        }
        {validator.current.message(I18n.t("sign_up.one.email"), user.email, 'required|email', customEmailMessages)}
      </div>
      <div className="flex flex-column">
        <div className="Field mt2">
          <label htmlFor="user_password">{I18n.t("sign_up.one.create_a_password")} * </label>
          <em>  {I18n.t("sign_up.one.min_6_characters")}</em>
          <br />
          <input
            autoComplete="new-password"
            type={passwordShown ? "text" : "password"}
            name="user[password]"
            id="user_password"
            value={user.password}
            onFocus={() => setPassInfo(true)}
            onChange={(e) => handlePassword(e)}
            onBlur={() => validator.current.showMessageFor(I18n.t("sign_up.one.password"))}
            required
          />
          <img
            src={Eye}
            alt="Password toggle"
            className="fill-green"
            onClick={() => setPasswordShown(!passwordShown)}
            style={{ marginLeft: '-56px', marginBottom: '-15px', cursor: 'pointer', padding: '8px' }}
          />
          {
            validator.current.message(
              I18n.t("sign_up.one.password"),
              user.password,
              ['required', { regex: '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$' }],
              customPasswordMessages
            )
          }
        </div>
        {
          showPassInfo &&
          <div className="mt2 b b-gray100 bg-white r-sm p3 flex flex-column justify-center black sign-up-validation">
            <p>
              <strong>{I18n.t("sign_up.password_tip.requirements_text")}</strong>
            </p>
            <ul>
              <Requirement
                message={I18n.t("sign_up.password_tip.contain_minimum_characters")}
                isValid={long}
              />
              <Requirement
                message={I18n.t("sign_up.password_tip.contain_one_uppercase")}
                isValid={uppercase}
              />
              <Requirement
                message={I18n.t("sign_up.password_tip.contain_one_lowercase")}
                isValid={lowercase}
              />
              <Requirement
                message={I18n.t("sign_up.password_tip.contain_one_number")}
                isValid={number}
              />
            </ul>
          </div>
        }
        <div className="Field mt1 mb2">
          <label htmlFor="user_password_confirmation">{I18n.t("sign_up.one.password_confirmation")}</label> *<br />
          <input
            autoComplete="new-password"
            type={passwordConfirmationShown ? "text" : "password"}
            name="user[password_confirmation]"
            id="user_password_confirmation"
            value={user.passwordConfirmation}
            onChange={(e) => handleChange(e)}
            onBlur={() => validator.current.showMessageFor(I18n.t("sign_up.one.password_confirmation"))}
            required
          />
          <img
            src={Eye}
            alt="Password confirmation toggle"
            className="fill-green"
            onClick={() => setPasswordConfirmationShown(!passwordConfirmationShown)}
            style={{ marginLeft: '-56px', marginBottom: '-15px', cursor: 'pointer', padding: '8px' }}
          />
          {validator.current.message(I18n.t("sign_up.one.password_confirmation"), user.passwordConfirmation, 'required', customPasswordConfirmationMessages)}
          {
            !user.passwordsMatch && user.passwordConfirmation ? <div className="srv-validation-message">{I18n.t("sign_up.validation.password_must_match_confirmation")}</div> : null
          }
        </div>
      </div>
      <div className="mb4 flex flex-column">
        <div onClick={submitForm} className="width50 text-center mt5 Button bc-teal white uppercase self-center">{I18n.t("sign_up.one.continue")}</div>
        <hr className="width80 mt5 hr-line" />
        {step === 1 && deviseLinks}
      </div>
    </>
  )
}
