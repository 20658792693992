import React from 'react'
import SelectSearch from 'react-select-search';

export default function SignupTwo({ countries, clubs, setUser, user, validator, submitForm, setStep }) {
  function handleRadio(e) {
    const value = e.currentTarget.value === 'true' ? true : false;
    setUser({ ...user, [`${e.currentTarget.name}`]: value })
  }

  const clubsCopy = clubs.map((club) => {
    return { name: club.display_name, value: `${club.id}` }
  });
  const countriesCopy = countries.map((country) => {
    return { name: country.display_name, value: `${country.country_id}` }
  });

  const customCountryMessages = { messages: { required: I18n.t("sign_up.validation.required_country") } }
  const customClubMessages = { messages: { required: I18n.t("sign_up.validation.required_club") } }

  function setCountryValues(value) {
    const country = countries.find(country => {
      return country.country_id == value
    })
    setUser({ ...user, country: value, address: { ...user.address, countryOther: country.display_name } })
  }

  return (
    <>
      <div className="mt2">
        <SelectSearch
          options={countriesCopy}
          placeholder={I18n.t("sign_up.two.which_country_do_you_reside_in")}
          emptyMessage="Not found"
          onChange={(value) => setCountryValues(value)}
          value={user.country}
          search
        />
        {validator ? validator.current.message(`field`, user.country, 'required',
          customCountryMessages) : null}
      </div>

      <div className="Field mt2" >
        <div>
          <p className="black uppercase">{I18n.t("sign_up.two.are_you_currently_affiliated")}</p>
        </div>
        <div>

          <label>
            <input
              type="radio"
              name="isAffiliated"
              value="true"
              checked={user.isAffiliated === true}
              onChange={(e) => handleRadio(e)} />
            {I18n.t("sign_up.two.yes_option")}
          </label>
          <label className="ml2">
            <input
              type="radio"
              name="isAffiliated"
              value="false"
              checked={user.isAffiliated === false}
              onChange={(e) => handleRadio(e)} />
            {I18n.t("sign_up.two.no_option")}
          </label>
        </div>
      </div>
      {
        user.isAffiliated ? (
          <>
            <div className="mt2">
              <SelectSearch
                options={clubsCopy}
                placeholder={I18n.t("sign_up.two.which_club")}
                emptyMessage="Not found"
                onChange={(value) => setUser({ ...user, club: value })}
                value={user.club}
                search
              />
              {validator ? validator.current.message(`field`, user.club, 'required',
                customClubMessages
              ) : null}
            </div>
            <div className="Field mt2" >
              <div>
                <p className="black uppercase">{I18n.t("sign_up.two.are_you_a_club_president")}</p>
              </div>
              <div>

                <label>
                  <input
                    type="radio"
                    name="clubPresident"
                    value="true"
                    checked={user.clubPresident === true}
                    onChange={(e) => handleRadio(e)} />
                  {I18n.t("sign_up.two.yes_option")}
                </label>
                <label className="ml2">
                  <input
                    type="radio"
                    name="clubPresident"
                    value="false"
                    checked={user.clubPresident === false}
                    onChange={(e) => handleRadio(e)} />
                  {I18n.t("sign_up.two.no_option")}
                </label>
              </div>
            </div>
          </>
        ) : null
      }
      <div className="mb4 flex">
        <div onClick={() => setStep(1)} className="width90 mt5 Button bc-gray white uppercase mr1">{I18n.t("sign_up.one.previous")}</div>
        <div onClick={submitForm} className="width90 mt5 Button bc-teal white uppercase ml1">{I18n.t("sign_up.one.continue")}</div>
      </div>
    </>
  )
}
