document.addEventListener('turbolinks:load', function() {
  initFlashClose()
})

document.addEventListener('partial:complete', function() {
  initFlashClose()
})

function initFlashClose() {
  let flashList = document.querySelectorAll('.Flash-Close')
  if (flashList) {
    flashList.forEach(flash => {
      flash.addEventListener('click', function () {
        let flash = document.querySelector('.Flash')
        let parent = flash.parentNode;
        parent.removeChild(flash)
      })  
    })
  }
}