document.addEventListener('turbolinks:load', function() {
  commPrefListeners();
  sortMembersTable();
  toggleOtherField();
  toggleFlaggedReason();
  addClubRole();
  eventNotificationListener();
  announcementNotificationListener();
  adminDashboardDetectionListener();
});

$(document).on('ajax:complete', function() {
  commPrefListeners();
});

function adminDashboardDetectionListener() {
  bodyTagMain = $('.bc-white')
  navTagMain = $('.navigation')
  
  if (bodyTagMain.length === 1 && navTagMain.length === 1) {
    $(".app-container .navigation").append('<a class="navigation__link navigation__link--inactive" href="/locales/en/translations">Translations</a>')
  }
}
// Communication Preference checkboxes
function commPrefListeners() {
  commPrefForm = $('#comm-pref-form');
  commPrefOptIn = $('#email_preference_opt_in');
  commPrefCheckboxes = $('#comm-pref-form input[type=checkbox]');
  commPrefOptions = $('.comm-pref-option');

  if (commPrefForm.length == 1) {
    commPrefOptIn.change(function() {
      if (commPrefOptIn.prop('checked')) {
        commPrefOptions.css('color', 'black');
        checkboxesOperational();
        checkboxesSelected();
      } else {
        commPrefOptions.css('color', 'lightgray');
        checkboxesSelected();
        checkboxesOperational();
      }
    });
    commPrefCheckboxes.change(function() {
      commPrefForm[0].requestSubmit();
    });
  }
}

// Club events notification preference checkbox
function eventNotificationListener() {
  eventNotificationForm = $('#event-notify-form');
  eventNotificationCheckbox = $('#event_notification');
  eventNotificationOption = $('.event-notify-option');

  if (eventNotificationForm.length == '1') {
    toggleNotificationCheckbox(eventNotificationCheckbox, eventNotificationForm)
  }
}

// Club announcements notification preference checkbox
function announcementNotificationListener() {
  announcementNotificationForm = $('#announcement-notify-form');
  announcementNotificationCheckbox = $('#announcement_notification');

  if (announcementNotificationForm.length == '1') {
    toggleNotificationCheckbox(announcementNotificationCheckbox, announcementNotificationForm)
  }
}

function checkboxesOperational() {
  commPrefCheckboxes[0].disabled = !commPrefCheckboxes[0].disabled;
  commPrefCheckboxes[1].disabled = !commPrefCheckboxes[1].disabled;
  commPrefCheckboxes[2].disabled = !commPrefCheckboxes[2].disabled;
  commPrefCheckboxes[3].disabled = !commPrefCheckboxes[3].disabled;
  commPrefCheckboxes[4].disabled = !commPrefCheckboxes[4].disabled;
  commPrefCheckboxes[5].disabled = !commPrefCheckboxes[5].disabled;
}

function checkboxesSelected() {
  commPrefCheckboxes.prop('checked', commPrefOptIn.prop('checked'));
}

function toggleNotificationCheckbox(checkbox, form) {
  toggleValue = $('#toggle_value');

  if (toggleValue.val() == "true") {
    checkbox.prop('checked', true);
  } else if (toggleValue.val() == "false") {
    checkbox.prop('checked', false);
  }

  checkbox.change(function() {
    form[0].requestSubmit();
  });
}

// Sort Membership List Table
function sortMembersTable() {
  tbody = $('.members-table');
  nameHeader = $('.members-name-header');
  nameOrder = $('.member-name-order');
  clubRoleHeader = $('.members-club-role-header');
  clubRoleOrder = $('.member-club-role-order');

  if (nameHeader.length == 1) {
    nameHeader.click(function() {
      sortColumn(nameOrder, '0');
    });
    clubRoleHeader.click(function() {
      sortColumn(clubRoleOrder, '1');
    });
  }
}

function sortColumn(columnOrder, position) {
  tbody.find('tr').sort(function(a, b) {
    if (columnOrder.val() == 'asc') {
      return $('td:eq(' + position + ')', b).text().localeCompare($('td:eq(' + position + ')', a).text());
    } else {
     return $('td:eq(' + position + ')', a).text().localeCompare($('td:eq(' + position + ')', b).text());
    }
  }).appendTo(tbody);
  setOrderValue(columnOrder);
}

function setOrderValue(columnOrder) {
  if (columnOrder.val() == 'asc') {
    columnOrder.val('desc');
  } else {
    columnOrder.val('asc');
  }
}

// Member Management page
function toggleOtherField() {
  otherRole = $('#edit-member-club-role-other')
  selectedRole = $('#user_club_role_internal_name')
  club_member_edit_form_cstm_class = $('.club_member_edit_form_cstm_class')

  if (club_member_edit_form_cstm_class.length === 1) {
    currentMethodvalue = $("input[name='_method']").val();
    currentMethodvalue = $("input[name='_method']").val('patch');
    
  }

  if (selectedRole.length != 0) {
    selectedRole.change(function() {
      if (selectedRole.val() == 'other') {
        otherRole.css('display', 'flex');
      } else {
        otherRole.css('display', 'none');
        otherRole.val('');
      }
    });
  }
}

function toggleFlaggedReason() {
  flaggedReason = $('#flagged-reason');
  userStatusDropdown = $('#user_memberships_attributes_0_status');

  if (userStatusDropdown.length == 1) {
    userStatusDropdown.change(function() {
      if (userStatusDropdown.val() == 'REJECTED' || userStatusDropdown.val() == 'SUSPENDED') {
        flaggedReason.css('display', 'block');
        $('#flagged-reason textarea').attr("required", true);
      } else {
        flaggedReason.css('display', 'none');
        $('#flagged-reason textarea').attr("required", false);
      }
    })
  }
}

function addClubRole() {
  if ($('#add-role-button').length == 1) {
    setClubRoleTrash();
    $('#add-role-button').click(function() {
      selectClubRole();
      setClubRoleTrash();
    });
  }
}

function setClubRoleTrash() {
  $('.club-role-item svg').click(function() {
    $(this).parent().remove();
  });
}

function selectClubRole() {
  trashSvg = `<svg width="14" height="18" viewBox="0 0 14 18" fill="lightgray" xmlns="http://www.w3.org/2000/svg"
              class="jam jam-trash-can pointer mr1">
              <path d="M11 6V16H3V6H11ZM9.5 0H4.5L3.5 1H0V3H14V1H10.5L9.5 0ZM13 4H1V16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4Z">
              </path></svg>`
  selectedClubRole = $('#user_club_role_internal_name').val();
  if (selectedClubRole == 'other') {
    if ($('#user_club_role_other').val() != '') {
      displayClubRole(trashSvg, $('#user_club_role_other').val(), $('#user_club_role_other').val());
      $("#flash-section").html('');
    }else{
      $("#flash-section").html('<div class="Flash bg-white p2 shadow r-sm z10"><div class="text-right"><div class="Flash-Close Icon Icon-sm fill-gray100 pointer lh0"><svg xmlns="http://www.w3.org/2000/svg" viewBox="-2 -2 24 24" preserveAspectRatio="xMinYMin" class="jam jam-close-circle"><path d="M11.414 10l2.829 2.828a1 1 0 0 1-1.415 1.415L10 11.414l-2.828 2.829a1 1 0 1 1-1.415-1.415L8.586 10 5.757 7.172a1 1 0 0 1 1.415-1.415L10 8.586l2.828-2.829a1 1 0 0 1 1.415 1.415L11.414 10zM10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"></path></svg></div></div>Please enter the Other Title.</div>');
    }
  } else if (selectedClubRole != '') {
    displayClubRole(trashSvg, getI18nClubRole(selectedClubRole), selectedClubRole);
  }
}

function getI18nClubRole(role) {
  return role
  // TODO: fix this once new interest group club roles are translated
  // return translatedRole = I18n.t('club_roles.' + role)
}

function displayClubRole(trash, displayedRole, paramRole) {
  if ($('.listed-club-role:contains(' + displayedRole + ')').text() == displayedRole) {
    resetClubRoleFields();
    return;
  }
  $('#club-roles-list').append('<div class="flex flex-row align-center club-role-item mb1">' + trash + `
  <div class="listed-club-role">` + displayedRole + '</div><input type="hidden" name="club_role[' + Math.random() + `]"
  value="` + paramRole + '"></div>');
  resetClubRoleFields();
}

function resetClubRoleFields() {
  $('#user_club_role_internal_name').val('');
  $('#user_club_role_other').val('');
  $('#edit-member-club-role-other').css('display', 'none');
}
