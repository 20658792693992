import React, { useState } from 'react'
import Check from '../images/jamicons/check.svg'
import More from '../images/jamicons/more-horizontal-f.svg'
import Envelope from '../images/jamicons/envelope-f.svg'

const showMoreStyles = {
  display: 'block',
  position: 'absolute',
  lineHeight: '25px',
  border: '2px solid gray',
  borderRadius: '1px',
  width: 122,
}

export default function TableRow({ user, setUsers, currentUser, handleClick }) {

  const [showMore, setMore] = useState(false)

  function textColor() {
    if (user.status == 'REJECTED' || user.status == 'SUSPENDED') {
      return 'gray'
    } else {
      return 'black'
    }
  }

  function userTextColor() {
    if (user.status == 'REJECTED' || user.status == 'SUSPENDED') {
      return 'gray'
    } else if (user.status == 'APPROVED') {
      return 'teal'
    } else {
      return 'black'
    }
  }

  function displayClubRole(role) {
    if (role == 'other'){
      return user.roles_other
    } else {
      return I18n.t("club_roles."+role)
    }
  }

  function displayEmailLink() {
    return (
      <>
        <td className="cell-data cell-data--string">
          <a
            onClick={() => handleClick(user, 'GENERIC')}
            className={"action-show pointer " + textColor()} >
            {user.email}
          </a>
        </td>
      </>
    )
  }
  function displayDeceasedStatus(){
    if (user.deceased == true) {
      return (
        <>
          <td align="center">
            <span className="no_display_span">yes</span>
            <img src={Check} alt="React Logo" />
          </td>
        </>
      )
    }else{
      return (
        <>
          <td align="center">
          <span className="no_display_span">no</span>
          </td>
        </>
      )  
    }
  }

  function displayStatus() {
    if (user.id == currentUser.id) {
      return (
        <>
          <td align="center">
            <img src={Check} alt="React Logo" />
          </td>
          <td align="center">
            <div
              className="uppercase gray text-center"
             ></div>
          </td>
        </>
      )
    } if (user.status == 'NEW' || user.status == null) {
      return (
        <>
          <td align="center">
            <div
              className="club-approve-button round bg-teal uppercase text-center white ultrabold pointer"
              onClick={() => handleClick(user, 'APPROVED')}
            >{I18n.t("admin_approval.approve")}</div>
          </td>
          <td align="center">
            <div
              className="club-reject-button round bg-purple uppercase text-center white ultrabold pointer"
              onClick={() => handleClick(user, 'REJECTED')}
            >{I18n.t("admin_approval.reject")}</div>
          </td>
        </>
      )
    } else if (user.status == 'REJECTED') {
      return (
        <>
          <td align="center">
            <div
              className="uppercase ultrabold text-center gray StatusButton"
            >{I18n.t("admin_approval.rejected")}</div>
          </td>
          <td align="center" onClick={() => setMore(!showMore)} onMouseLeave={() => setMore(false)}>
            {/* <img src={More} alt="React Logo" className="pointer" />
            <div style={showMore ? showMoreStyles : null} className="UpArrow hide relative text-center">
              <p
                className="pl2 pr2 pointer status"
                onClick={() => handleClick(user, 'APPROVED')}
                style={{ borderBottom: '1px solid gray' }}
              >{I18n.t("admin_approval.approve")}</p>
              <p
                className="pl2 pr2 pointer status"
                onClick={() => handleClick(user, 'SUSPENDED')}
              >{I18n.t("admin_approval.suspend")}</p>
            </div> */}
          </td>
        </>
      )
    } else if (user.status == 'APPROVED') {
      return (
        <>
          <td align="center">
            <img src={Check} alt="React Logo" />
          </td>
          <td align="center" onClick={() => setMore(!showMore)} onMouseLeave={() => setMore(false)}>
            {/* <img src={More} alt="React Logo" className="pointer" />
            <div style={showMore ? showMoreStyles : null} className="UpArrow hide relative text-center">
              <p
                className="pl2 pr2 pointer status"
                onClick={() => handleClick(user, 'REJECTED')}
                style={{ borderBottom: '1px solid gray' }}
              >
                {I18n.t("admin_approval.reject")}
              </p>
              <p
                className="pl2 pr2 pointer status"
                onClick={() => handleClick(user, 'SUSPENDED')}
              >
                {I18n.t("admin_approval.suspend")}
              </p>
            </div> */}
          </td>
        </>
      )
    } else {
      return (
        <>
          <td align="center">
            <div
              className="uppercase text-center gray ultrabold StatusButton"
            >{I18n.t("admin_approval.suspended")}</div>
          </td>
          <td align="center" onClick={() => setMore(!showMore)} onMouseLeave={() => setMore(false)}>
            {/* <img src={More} alt="React Logo" className="pointer" />
            <div style={showMore ? showMoreStyles : null} className="UpArrow hide relative text-center">
              <p
                className="pl2 pr2 pointer status"
                onClick={() => handleClick(user, 'APPROVED')}
                style={{ borderBottom: '1px solid gray' }}
              >{I18n.t("admin_approval.approve")}</p>
              <p
                className="pl2 pr2 pointer status"
                onClick={() => handleClick(user, 'REJECTED')}
              >{I18n.t("admin_approval.reject")}</p>
            </div> */}
          </td>
        </>

      )
    }
  }
  return (
    <>
      <tr className="js-table-row" tabIndex="0" role="link" data-url={`/members/${user.slug}/profile`}>
        <td className="flex flex-column cell-data cell-data--string">
          <div className="flex align-center cell-data cell-data--string">
            <div className="dash-profile-image mr2">
              <img src={`${user.image_url}`} />
            </div>
            <a href={`/clubs/${user.club_id}/members/${user.slug}`} className={"action-show ultrabold " + userTextColor()}>
              {`${user.last_name || ""}, ${user.first_name || ""}`}
            </a>
          </div>
          <div className="role-list">
            { user.roles.map( (role, index) => {
              return <li key={index}>{displayClubRole(role)}</li>
            })}
          </div>
        </td>
        <td className="cell-data cell-data--string">
          <a href={`/clubs/${user.club_id}/members/${user.slug}`} className={"action-show " + textColor()}>
            {`${user.member_id || ""}`}
          </a>
        </td>
        {displayEmailLink()}
        {displayDeceasedStatus()}
        {displayStatus()}
      </tr>
    </>
  )
}
