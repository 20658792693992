document.addEventListener('turbolinks:load', function() {
  clubListListeners();
  clubModalListeners();
  handleToggleColor();
  regionListListeners();
})

function clubListListeners() {
  clubListForm = $('#club-list-form')
  clubListOption = $('.club-list-option')
  countryDropdown1 = $('#autofill')[0]
  countryDropdown2 = $('#country')[0]
  clubPhysicalAttr = $('#club_physical')

  clubNumberAttr = document.getElementById('club_club_number')
  clubNumberField = document.getElementsByClassName('club_number_field')[0]
  groupNumberField = document.getElementsByClassName('group_number_field')[0]
  // clubDropDown = $('#club_club')

  if (clubListForm.length === 1) {
    clubListOption.change(function(event) {
      clubListForm[0].requestSubmit();
    })
  }

  if (clubPhysicalAttr.length >0) {
    physicalIsChecked()
    clubPhysicalAttr.click(function(){
      physicalIsChecked()
    })
  }

  // alert('hey');
}

function physicalIsChecked() {
  if (clubPhysicalAttr[0].checked) {
    handleDisplay(false, "block", "none")
  } else {
    handleDisplay(true, "none", "block")
  }
}

function handleDisplay(par1, par2, par3) {
  clubNumberAttr.disabled = par1;
  clubNumberField.style.display = par2;
  groupNumberField.style.display = par3;
}

function clubModalListeners() {
  if ($('.join-club-button').length > 0) {
    $('.join-club-button').click(function() {
      toggleModal($('#join-club-modal'))
    })
  }
  if ($('.leave-club-button').length > 0) {
    $('.leave-club-button').click(function() {
      toggleModal($('#leave-club-modal'))
    })
  }
}

function toggleModal(modal) {
  if (modal.css('display') == 'block') {
    modal.css('display', 'none')
  } else {
    modal.css('display', 'block')
  }
}

function handleToggleColor() {
  $('.sort-link').click(function(e) {
    $('.sort-link').each(function() {
      this.classList.remove("bold");
    })
    e.target.classList.add("bold");
  })
}

function regionListListeners(){
  var clubPage = (document.getElementById("club_region_id") != null);

  form_label = $('.field-unit__label');
  clubCountrySelect = $('#club_country_id');
  clubRegionSelect = $('#club_region_id');
  clubSubRegionSelect = $("#club_sub_region_I_id");
  clubSecondSubRegionSelect = $("#club_sub_region_II_id");
  
  if (clubPage){
    form_label.each(function() {
      $(this).css('text-transform', 'uppercase');
    });
    var editSelectedCountry = clubCountrySelect.val();
    var editSelectedRegion = clubRegionSelect.val();
    var editSelectedSubRegion = clubSubRegionSelect.val();
    var editSelectedSecondSubRegion = clubSecondSubRegionSelect.val();
    
    if(window.location.href.indexOf("edit") != -1)
    {
      console.log("edit page");    

      // ==============================

      if (!localStorage.getItem("reload")) {
          // console.log('not reloaded');
          localStorage.setItem("reload", "true");
          localStorage.removeItem("clubedit");
          localStorage.removeItem("editSelectedCountry");
          localStorage.removeItem("editSelectedRegion");
          localStorage.removeItem("editSelectedSubRegion");
          localStorage.removeItem("editSelectedSecondSubRegion");

          // console.log('not reload editSelectedCountry : '+editSelectedCountry);
          // console.log('not reload editSelectedRegion : '+editSelectedRegion);
          // console.log('not reload editSelectedSubRegion : '+editSelectedSubRegion);
          // console.log('not reload editSelectedSecondSubRegion : '+editSelectedSecondSubRegion);

          if (!localStorage.getItem("editSelectedCountry")) {
            localStorage.setItem("editSelectedCountry", editSelectedCountry);
          }
          if(editSelectedRegion !== null && editSelectedRegion != ''  ){
            if (!localStorage.getItem("clubedit")) {
              localStorage.setItem("clubedit", "true");
            }
            if (!localStorage.getItem("editSelectedRegion")) {
              localStorage.setItem("editSelectedRegion", editSelectedRegion);
            }
            if (!localStorage.getItem("editSelectedSubRegion")) {
              localStorage.setItem("editSelectedSubRegion", editSelectedSubRegion);
            }
            if (!localStorage.getItem("editSelectedSecondSubRegion")) {
              localStorage.setItem("editSelectedSecondSubRegion", editSelectedSecondSubRegion);
            }
            
          }
          location.reload();
          // console.clear()
          return;
      }
      else {
          localStorage.removeItem("reload");
          // console.log('reload removed in local storage')
          // console.log('onreload  editSelectedCountry : '+localStorage.getItem("editSelectedCountry"));
          // console.log('onreload  editSelectedRegion : '+localStorage.getItem("editSelectedRegion"));
          // console.log('onreload  editSelectedSubRegion : '+localStorage.getItem("editSelectedSubRegion"));
          // console.log('onreload  editSelectedSecondSubRegion : '+localStorage.getItem("editSelectedSecondSubRegion"));
      
          if(localStorage.getItem("editSelectedRegion") && localStorage.getItem("editSelectedRegion") != null && localStorage.getItem("editSelectedRegion") != ''  ){
            // console.log('up');
            clubRegionSelect[0].selectize.addItem(localStorage.getItem("editSelectedRegion"));
            clubCountrySelect[0].selectize.addItem(localStorage.getItem("editSelectedCountry"));
            setSubregionEdit();
            setSecondSubregionedit();
          }else{
            // console.log('down')
            clubCountrySelect[0].selectize.addItem(localStorage.getItem("editSelectedCountry"));
            clubRegionSelect[0].selectize.clear();
            // setRegionCustom();
            clubSubRegionSelect[0].selectize.clear();
            clubSecondSubRegionSelect[0].selectize.clear();
            clubSubRegionSelect[0].selectize.disable();
            clubSecondSubRegionSelect[0].selectize.disable();
          }
      }   
      
      // ===========================
    }else{
      // console.log("not edit page");    
      if (!localStorage.getItem("reload")) {
          localStorage.setItem("reload", "true");
          location.reload();
          console.clear()
          return;
      }
      else {
          localStorage.removeItem("reload");
      }   
      localStorage.removeItem("clubedit");
      localStorage.removeItem("editSelectedRegion");
      localStorage.removeItem("editSelectedSubRegion");
      localStorage.removeItem("editSelectedSecondSubRegion");
      clubSubRegionSelect[0].selectize.disable();
      clubSecondSubRegionSelect[0].selectize.disable();
    }


    

    // console.log('editSelectedRegion : '+editSelectedRegion);
    // console.log('editSelectedSubRegion : '+editSelectedSubRegion);
    // console.log('editSelectedSecondSubRegion : '+editSelectedSecondSubRegion);

  

    //Always
    clubRegionSelect[0].selectize.on('change', setSubregion);
    clubSubRegionSelect[0].selectize.on('change', setSecondSubregion);
  }

  // function setRegionCustom(){

  //     $.ajax({
  //       url: '/get_all_region_custom',
  //       method: "GET",  
  //       dataType: "json",
  //       // data: {region: selectedregion},
  //       error: function (xhr, status, error) {
  //           console.error('AJAX Error: ' + status + error);
  //       },
  //       success: function (response) {
  //           console.log(response);
  //           var cstmregiontmp = clubRegionSelect[0].selectize;
  //           cstmregiontmp.clear()
  //           cstmregiontmp.clearOptions();
           
  //           var optionsList = [];
  //           var itemsList = [];
  //           $.each(response, function() {
  //             optionsList.push( {
  //                             value: this.id,
  //                             text: this.display_name
  //                     });
  //             itemsList.push({
  //                           value: this.id,
  //                             text: this.display_name
  //                         });
  //           });
  //           cstmregiontmp.addOption(optionsList)
  //       }
  //     });
  // 	}


  function setSubregion(){

    var selectedregion = clubRegionSelect.val();
    if(selectedregion != ''){
      clubSubRegionSelect[0].selectize.enable();

      $.ajax({
        url: '/get_subregion_by_region/'+selectedregion,
        method: "GET",  
        dataType: "json",
        // data: {region: selectedregion},
        error: function (xhr, status, error) {
            console.error('AJAX Error: ' + status + error);
        },
        success: function (response) {
            // console.log(response);
            var subregiontmp = clubSubRegionSelect[0].selectize;
            subregiontmp.enable();
            subregiontmp.clear()
            subregiontmp.clearOptions();

            var secondsubregiontmp = clubSecondSubRegionSelect[0].selectize;
            secondsubregiontmp.disable();
            secondsubregiontmp.clear()
            secondsubregiontmp.clearOptions();
           
            var optionsList = [];
            var itemsList = [];
            $.each(response, function() {
              optionsList.push( {
                              value: this.id,
                              text: this.display_name
                      });
              itemsList.push({
                            value: this.id,
                              text: this.display_name
                          });
            });
            subregiontmp.addOption(optionsList)
        }
      });
  	}

  }

  function setSecondSubregion(){

    var selectedsubregion = clubSubRegionSelect.val();
    if(selectedsubregion != ''){
      clubSecondSubRegionSelect[0].selectize.enable();

      $.ajax({
        url: '/get_secondsubregion_by_subregion/'+selectedsubregion,
        method: "GET",  
        dataType: "json",
        
        // data: {region: selectedregion},
        error: function (xhr, status, error) {
            console.error('AJAX Error: ' + status + error);
        },
        success: function (response) {
            // console.log(response);
            var secondsubregiontmp = clubSecondSubRegionSelect[0].selectize;
            secondsubregiontmp.enable();
            secondsubregiontmp.clear()
            secondsubregiontmp.clearOptions();
           
            var optionsList = [];
            var itemsList = [];
            $.each(response, function() {
              optionsList.push( {
                              value: this.id,
                              text: this.display_name
                      });
              itemsList.push({
                            value: this.id,
                              text: this.display_name
                          });
            });
            secondsubregiontmp.addOption(optionsList)
        }
      });
  	}

  }

  function setSubregionEdit(){
    var selectedregion = localStorage.getItem("editSelectedRegion");
    // console.log('123 setSubregionEdit'+selectedregion)
    if(selectedregion != ''){
      // console.log('/get_subregion_by_region/'+selectedregion)
      $.ajax({
        url: '/get_subregion_by_region/'+selectedregion,
        method: "GET",  
        dataType: "json",
        cache: false,
        // data: {region: selectedregion},
        error: function (xhr, status, error) {
            console.error('setSubregionEdit AJAX Error: ' + status + error);
        },
        success: function (response) {
          // console.log('123 response')
            // console.log(response);
            var subregiontmp = clubSubRegionSelect[0].selectize;
            subregiontmp.enable();
            subregiontmp.clear()
            subregiontmp.clearOptions();
           
            var optionsList = [];
            var itemsList = [];
            $.each(response, function() {
              optionsList.push( {
                              value: this.id,
                              text: this.display_name
                      });
              itemsList.push({
                            value: this.id,
                              text: this.display_name
                          });
            });
            // console.log(optionsList);
            // console.log(localStorage.getItem("editSelectedSubRegion"))
            subregiontmp.addOption(optionsList)
            $.each(response, function() {
                if (this.id == localStorage.getItem("editSelectedSubRegion")) {
                  subregiontmp.addItem(this.id,this.display_name);
                }
                  
            });
            
        }
      });
  	}

  }

  function setSecondSubregionedit(){

    var selectedsubregion = localStorage.getItem("editSelectedSubRegion");
    if(selectedsubregion != ''){
      clubSecondSubRegionSelect[0].selectize.enable();
      // console.log('/get_secondsubregion_by_subregion/'+selectedsubregion)
      $.ajax({
        url: '/get_secondsubregion_by_subregion/'+selectedsubregion,
        method: "GET",  
        dataType: "json",
        cache: false,
        // data: {region: selectedregion},
        error: function (xhr, status, error) {
            // console.error('setSecondSubregionedit AJAX Error: ' + status + error);
        },
        success: function (response) {
            // console.log(response);
            var secondsubregiontmp = clubSecondSubRegionSelect[0].selectize;
            secondsubregiontmp.enable();
            secondsubregiontmp.clear()
            secondsubregiontmp.clearOptions();
           
            var optionsList = [];
            var itemsList = [];
            $.each(response, function() {
              optionsList.push( {
                              value: this.id,
                              text: this.display_name
                      });
              itemsList.push({
                            value: this.id,
                              text: this.display_name
                          });
            });
            secondsubregiontmp.addOption(optionsList)
            $.each(response, function() {
              if (this.id == localStorage.getItem("editSelectedSecondSubRegion")) {
                secondsubregiontmp.addItem(this.id,this.display_name);
              }
          });
          localStorage.removeItem("clubedit");
        }
      });
  	}
    
  }
}


